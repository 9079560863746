import React, { lazy, useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import {Hidden} from '@material-ui/core';
import {restoreSettings,storeSettings} from '../../../template/SettingsContext';
import { useTheme } from '@material-ui/core/styles';
import  useResize  from './useResize';
import clsx from 'clsx';
import AppBar from '../components/AppBar';

const backgroundImage =
  '/images/site/header7.png';

  const backgroundCandel =
    '/images/site/candel1.png';

  const restavrationImage =
    '/images/site/restavrationHeader3.png';

  const fhererImage =
      '/images/site/ouk.jpg';

  const fhererImageNight =
        '/images/site/night2.png';

const styles = theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: '60%',
    backgroundPosition: 'center',
    zIndex:100,
  },
  backgroundCandel: {
    width: '300px',
    backgroundPosition: 'center',
    marginTop:-20,
    zIndex:300,
  },
  backgroundUp: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: '160%',
    backgroundPosition: 'center',
    marginTop:-200,
    zIndex:100,
  },
  backgroundSubstrate: {
    backgroundImage: `url(${fhererImage})`,
    backgroundColor: theme.palette.secondary, // Average color of the background image.
    backgroundPosition: 'center',
  },
  backgroundSubstrateNight: {
    backgroundImage: `url(${fhererImageNight})`,
    backgroundColor: theme.palette.secondary, // Average color of the background image.
    backgroundPosition: 'center',
  },
  root: {
    fontFamily: 'SynodDisplay',
    marginTop:100,
    zIndex:200
  },
  discription: {
    fontFamily: 'SynodDisplay',
    zIndex:200,
    color:theme.palette.primary.dark,
    fontSize:30,
  },
  discription2: {
    fontFamily: 'SynodDisplay',
    zIndex:200,
    color:theme.palette.primary.default,
    fontWeight: 900
  },
  discriptionUp: {
    fontFamily: 'SynodDisplay',
    zIndex:200,
    color:theme.palette.primary.headerText,
  },
  rootUp: {
    fontFamily: 'SynodDisplay',
    marginTop:100,
    zIndex:200
  },
  button: {
    minWidth: 200,
    backgroundColor: '#c5ff3342'
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(40),

  },
  more: {
    marginTop: theme.spacing(2),
  },
  headerDown: {
    backgroundPosition: 'center',
    marginTop: '80px',
    zIndex:200,
    //filter:'sepia(60%) hue-rotate(0deg) saturate(500%) contrast(60%) brightness(180%)'
    // /filter: 'sepia(500%) hue-rotate(90deg) saturate(1000%) contrast(50%) brightness(200%)'
  },
  discriptionDown: {
    backgroundPosition: 'center',
    marginTop:'-30px',
    fontSize:50,
    fontFamily: 'SynodDisplay',
    zIndex:200,
    color:theme.palette.primary.dark
  },
  headerUp: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '20px',
    //filter:'sepia(60%) hue-rotate(0deg) saturate(500%) contrast(60%) brightness(180%)',
    //    filter: 'sepia(60%) hue-rotate(100deg) saturate(100%) contrast(140%) brightness(140%)',
    width: '100%',
    zIndex:200
  }
});


const ProductHero = props => {
  const { classes } = props;
  const [selectedTheme, setSelectedTheme] = useState();
  const [sizePr, setSizePr] = useState(useResize());
  const theme_m = useTheme();

  useEffect(() => {
    const setii = restoreSettings()
    setSelectedTheme(setii)
    console.log(sizePr);
  }, [theme_m.theme]);


  return (
    <AppBar >
    { selectedTheme &&
    <div className={clsx(selectedTheme.theme == 'LIGHT' && classes.backgroundSubstrate, selectedTheme.theme == 'DARK' && classes.backgroundSubstrateNight) }>
    <Hidden mdDown>
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img
        align="center"
        src={`${restavrationImage}`}
        className={clsx(classes.headerDown)}
        marked="center"
      >
      </img>
      <Typography
        align="center"
        className={classes.discriptionDown}

        variant="h5"
      >
        Художественно  столярная
      </Typography>
      <Typography
        align="center"
        className={classes.discription2}
        style={{fontSize:30}}
        variant="h5"
      >
        Всё возможно в укрепляющем нас Господе Иисусе Христе
      </Typography>
      <Typography
        align="center"
        style={{marginTop:'250px'}}
        className={classes.discription2}
        color="inherit"
        variant="h5"
      >
        Реставрационные, художественные, столярные работы на заказ
      </Typography>
      <Button
        className={classes.button}
        color="secondary"
        component="a"
        href="/views/1"
        size="large"
        style = {{marginTop:'20px',zIndex:200}}
        variant="contained"
      >
        Посмотреть продукцию
      </Button>




      <Typography
        className={classes.discription2}
        color="inherit"
        variant="body2"
        variant="h6"
      >
        К делу с Любовью
      </Typography>
      <img
        align="center"
        src={`${backgroundCandel}`}
        className={clsx(classes.backgroundCandel)}
        marked="center"
        style={{marginTop:-30,filter:'sepia(0%) hue-rotate(0deg) saturate(300%) contrast(100%) brightness(140%)'}}
      >
      </img>
  </ProductHeroLayout>
</Hidden>
<Hidden lgUp>
<ProductHeroLayout backgroundClassName={ classes.backgroundUp}>
      <Hidden lgUp>
      <img
        align="center"
        src={`${restavrationImage}`}
        className={classes.headerUp}
        marked="center"
      >
      </img>
      <Typography
        align="center"
        className={classes.discriptionUp}
        style={{fontSize:30}}
        color="primary"
        variant="h5"
      >
        Художественно столярная
      </Typography>
      </Hidden>
      <Hidden lgUp>
      <Typography
        align="center"
        className={classes.discriptionUp}
        color="primary"
        variant="h5"
      >
        Всё возможно в укрепляющем нас Господе Иисусе Христе
      </Typography>
      </Hidden>
      <Hidden lgUp>
      <Typography
        align="center"
        className={classes.discriptionUp}
        style={{marginTop:200,marginBottom:'20px'}}
        color="primary"
        variant="h5"
      >
        Реставрационные, художественные, столярные работы на заказ
      </Typography>
      </Hidden>
      <Button
        className={classes.button}
        color="secondary"
        component="a"
        href="/views/1"
        size="large"
        style = {{marginTop:'0px',zIndex:200,marginBottom:'10px'}}
        variant="contained"
      >
        Посмотреть продукцию
      </Button>
      <Hidden lgUp>
      <Typography
        className={classes.discriptionUp}
        color="inherit"
        variant="body2"
        variant="h6"
      >
        К делу с Любовью
      </Typography>
      </Hidden>
      <img
        align="center"
        src={`${backgroundCandel}`}
        className={clsx(classes.backgroundCandel)}
        marked="center"
        style={{marginTop:100}}
      >
      </img>
      </ProductHeroLayout>
    </Hidden>
    </div>
  }
  </AppBar>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);

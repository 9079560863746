import React,  { useState, useCallback,useEffect  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ImageGallery from 'react-image-gallery';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import $ from 'jquery';
import 'node_modules/react-image-gallery/styles/css/image-gallery.css'
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Foto } from './components';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    {...props}
         />;
});


const DialogShow = props => {
  const classes = useStyles();
  const {imgList,photos,productId,customer,setPhotos,block,setImgList, ...rest } = props;

  const [open,setOpen] = useState(false);


  const toggleGallery =() => {
    setOpen(false);
    console.log(open);
  }

  const clickGalary = () =>{
    setOpen(true);
  }

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    console.log('index ' + index);
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const seletImageShow = (photo,index ) =>{
    console.log('index ' + index);
    setCurrentImage(index);
    setViewerIsOpen(true);
    clickGalary();
  }

  const imageShow = (photo,index ) =>{
    console.log('index ' + index);
    var imgs = [];
    photo.forEach((item, i) => {
      imgs.push({
        original: item.src,
        thumbnail: item.src
      });
    });
    props.setImgList(imgs);
  }

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const selectImage = (photo,m_selected,index) =>{
    console.log('selectImage');
    setCurrentImage(index);
  }

  return (
    <div >
      <div onClick = {clickGalary}>
        {<Foto
          block = {block}
          customer={customer}
          imageShow = {imageShow}
          openLightbox = {openLightbox}
          photos = {photos}
          productId = {productId}
          seletImageShow = {seletImageShow}
          setPhotos = {setPhotos}
        />}
      </div>
      { !block &&
    <Dialog
      TransitionComponent={Transition}
      fullScreen
      onClose={toggleGallery}
      open={open}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="close"
            color="inherit"
            edge="start"
            onClick={toggleGallery}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ImageGallery
        items={imgList}
        startIndex = {currentImage}
      />
    </Dialog>
      }
      {block &&
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      }
    </div>
  );
};

DialogShow.propTypes = {
  block: PropTypes.boolean,
  customer: PropTypes.object.isRequired,
  imgList: PropTypes.array,
  photos: PropTypes.array,
  productId: PropTypes.number,
  setImgList: PropTypes.func,
  setPhotos: PropTypes.func.isRequired
};

export default DialogShow;

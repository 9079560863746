import React, { lazy, useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from '../components/AppBar';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
import { history } from 'authorization/_helpers';
import {Button} from '@material-ui/core';
import {userActions} from 'authorization/_actions';
import Grid from '@material-ui/core/Grid';
import CustomizedMenus from '../views/CustomizedMenus';
import Container from '@material-ui/core/Container';
import { IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {restoreSettings,storeSettings} from '../../../template/SettingsContext';
import { useTheme } from '@material-ui/core/styles';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { Typography } from '@material-ui/core';
import {Hidden} from '@material-ui/core';

const styles = theme => ({
  title: {
    fontSize: 24,
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between',
    minHeight:50,
    marginTop:20,
    minWidth:'100%'
  },
  left: {

  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    marginTop:2
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  buttonExit: {
    width:1,
    height:30
  },
  buttonSettings: {
    width:1,
    height:30
  },
  moon: {
    color: theme.palette.primary.contrastText,
    marginTop:7,
    padding: theme.spacing(2),
    marginLeft:5,
    marginRight:5,
    width:10,
    height:10
  },
  settings: {
    color: theme.palette.primary.contrastText,
    marginTop:-1,
    padding: theme.spacing(2),
    marginLeft:5,
    marginRight:5,
    width:10,
    height:10
  }
});

export function handleLogout (){
  userActions.logout();
  history.push('/sign-in');
}



const AppAppBar = props => {
  const theme_m = useTheme();

  const { classes,page } = props;
  const [selectedTheme, setSelectedTheme] = useState();

  useEffect(() => {
    const setii = restoreSettings()
    setSelectedTheme(setii)
    console.log(setii.theme);
  }, [theme_m.theme]);

  const handleSwitch = () => setSelectedTheme((prevSelectedTheme) => {
    console.log('asdsadsadsadsad');
    const root = document.getElementById('root');
    if (selectedTheme.theme === undefined || selectedTheme.theme === 'LIGHT') {

      const settings = {
        compact: true,
        direction: 'ltr',
        responsiveFontSizes: true,
        roundedCorners: true,
        theme: 'DARK',
      };
      storeSettings(settings)
      theme_m.theme = 'DARK'
      setSelectedTheme('DARK')
      window.location.href = window.location.href
      return 'DARK';


      return theme_m.theme;
    }
    const settings = {
      compact: true,
      direction: 'ltr',
      responsiveFontSizes: true,
      roundedCorners: true,
      theme: 'LIGHT',
    };
    storeSettings(settings)
    theme_m.theme = 'LIGHT'
    setSelectedTheme('LIGHT')
    window.location.href = window.location.href
    return 'LIGHT';
  });


  const logout = (e) =>{
    console.log('logout');
    handleLogout();
  }



  return (

    <div style = {{      marginBottom:70}}>
      { selectedTheme &&
      <AppBar position="fixed">
        <Container className={classes.toolbar} >
          <Grid
            container
            spacing={2}
          >
            <Grid item    >
            <Hidden mdDown>
            <Button
              color="inherit"
              href="/home"
              underline="none"
              variant="h6"
            >
              {'Главная'}
            </Button>
            </Hidden>
            <Hidden lgUp>
            <Button
              color="inherit"
              href="/home"
              underline="none"
              variant="h6"
              style={{fontSize:10}}
            >
              {'Главная'}
            </Button>
            </Hidden>
            </Grid>

            { page==='home' &&
          <Grid item  >
          <Hidden mdDown>
          <Button
            color="inherit"
            href="/views/9"
            underline="none"
            variant="h6"
          >
            {'Собственная мастерская'}
          </Button>
          </Hidden>
          <Hidden lgUp>
          <Button
            color="inherit"
            href="/views/9"
            underline="none"
            variant="h6"
            style={{fontSize:10}}
          >
            {'Собственная мастерская'}
          </Button>
          </Hidden>
          </Grid>
            }
            <CustomizedMenus />

            { page==='home' &&
           <Grid item  >
           <Hidden mdDown>
           <Button
             color="inherit"
             href="/views/22"
             underline="none"
             variant="h6"
           >
             {'О ценах'}
           </Button>
           </Hidden>
           <Hidden lgUp>
           <Button
             color="inherit"
             href="/views/22"
             underline="none"
             variant="h6"
             style={{fontSize:10}}
           >
             {'О ценах'}
           </Button>
           </Hidden>

           </Grid>
            }
            { page==='home' &&
          <Grid
            className={classes.right}
            item
          >
            <IconButton
              className={classes.settings}
              href="/sign-in"
            >
              <SettingsIcon  />
            </IconButton>
          </Grid>
            }
            <IconButton
              className={classes.moon}
              onClick={handleSwitch}
            >
              {selectedTheme.theme == 'DARK'
                ? <Brightness7Icon fontSize="small" />
                : <Brightness4Icon fontSize="small" /> }
            </IconButton>
            { page==='admin' &&
         <Grid
           className={classes.right}
           item
         >
           <Button
             className={classes.buttonExit}
             color="primary"
             onClick = {logout}
             size="large"
             variant="contained"
           >
             <ExitToAppIcon  />
           </Button>
         </Grid>
            }
          </Grid>
        </Container>
      </AppBar>
      }
    </div>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
};

export default withStyles(styles)(AppAppBar);

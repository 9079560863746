import React,{useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Iframe from 'react-iframe';
import Link from '@material-ui/core/Link';
import Typography from '../components/Typography';
import {  TextField } from '@material-ui/core';
import Snackbar from '../components/Snackbar';
import { Button } from '@material-ui/core';
import {getItem,mailing} from 'admin/Service/Service';
import * as EmailValidator from 'email-validator';
import clsx from 'clsx';


const styles = theme => ({
  root: {
    minHeight:600,
    marginBottom: 0,
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    display: 'flex',
    position: 'relative',
  },
  cardWrapper: {
    backgroundColor: theme.palette.secondary.light,
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.light,
  },
  cardContent: {
    maxWidth: 400,
    backgroundColor: theme.palette.secondary.light,
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    width: '100%',
  },
  imagesWrapper: {
    position: 'relative',

  },
  imageDots: {
    position: 'absolute',

    width: '100%',
    background: 'url(/static/onepirate/productCTAImageDots.png)',
  },
  image: {
    position: 'absolute',
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 600,
  },
  messageText:{
    textTransform: 'uppercase',
    fontFamily: 'SynodDisplay',
    color: theme.palette.primary.contrastText
  },
  description:{
    marginTop: theme.spacing(10),
    fontStyle: 'italic',
    fontFamily: 'FreeMono, monospace',
    color: theme.palette.primary.contrastText
  }
});

const ProductMap = props => {
  const { className,classes } = props;
  useEffect(() => {

  }, []);


  return (
    <section className={classes.root}>
      <Container
        className={classes.container}
        component="container"
      >
        <Grid container>
          <Grid
            item
            md={6}
            xs={12}
          >
            <div className={classes.card}>
              <div className={classes.cardContent}>
                <Typography
                  className={classes.messageText}
                  component="h2"
                  gutterBottom
                  variant="h2"
                >
                 О нас
                </Typography>
                <Typography
                  className={classes.description}
                  variant="h5"
                >
                 Мы работаем по всей территории Белоруссии. Заказы по России возможны через решение транспортного вопроса, не требующие командировочных работ по месту...
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid
            className={classes.imagesWrapper}
            item
            md={6}
            xs={12}
          >
            <Container
              className={classes.background}
              component="container"
            >
              <div style={{position:'relative',overflow:'hidden'}}>
                <Link
                  href="https://yandex.by/maps/org/drevo_delo_by/174752227758/?utm_medium=mapframe&utm_source=maps"
                  style={{color: '#eee', fontSize: '12px',position: 'absolute', top: '0px'}}
                >{'Drevo-delo.by'}</Link>
                <Link
                  href="https://yandex.by/maps/26034/zhodzina/category/art_workshop/184105850/?utm_medium=mapframe&utm_source=maps"
                  style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '14px'}}
                >{'Художественная мастерская в Жодино'}</Link>
                <Link
                  href="https://yandex.by/maps/26034/zhodzina/category/restoration_workshop/184108351/?utm_medium=mapframe&utm_source=maps"
                  style={{color:'#eee',fontSize:'12px',position:'absolute',top:'28px'}}
                >{'Реставрационная мастерская в Жодино'}</Link>
                <Iframe
                  allowfullscreen="true"
                  frameborder="1"
                  height="400"
                  src="https://yandex.by/map-widget/v1/org/drevo_delo_by/174752227758/?from=mapframe&ll=28.348270%2C54.080396&z=17.08"
                  style={{position:'relative'}}
                  width="560"
                />
              </div>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductMap.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductMap);

import React, {  useState, useEffect  } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {userActions} from 'authorization/_actions';
import useRouter from 'utils/useRouter';
import moment from 'moment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';
import Service from 'admin/Service/Service';
import { Grid } from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';
import {setStorePhotos} from 'admin/Service/Service';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import PersonIcon from '@material-ui/icons/PersonOutline';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import $ from 'jquery';

import { Label } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 10

  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const CustomerInfo = props => {
  const { customer, className,photos,setPhotos,descriptionImage,setDescriptionImage,changeDataImage,index,setMain,main, ...rest } = props;

  const { history } = useRouter();

  const classes = useStyles();

  const [openEdit, setOpenEdit] = useState(false);


  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleFieldChange = e => {
    e.persist();
    if(e.target.name==='descriptionImage'){
      setDescriptionImage(e.target.value);
    }
    photos[index].shortDescription = e.target.value;
    setPhotos(photos);
    setStorePhotos(photos);
  }

  useEffect(() => {
    let m = true;
    return () => {
      m = false;
    };

  }, []);

  const handleChange = event => {
    if(event.target.name==='main'){
      setMain(event.target.checked);
      photos.forEach((item, i) => {
        item.main=false;
      });
      photos[index].main = event.target.checked;
      setPhotos(photos);
      setStorePhotos(photos);
      photos.forEach((item, i) => {
        $( '#' + item.id).trigger( 'click' );
      });
    }
  };


  const handleLogout = () => {
    userActions.logout();
    history.push('/login');
    // dispatch(logout());
  };



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Информация для заполнения" />
      <Divider />
      <CardContent className={classes.content}>
        <Grid
          container
          xs={12}
        >
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={main}
                  color="primary"
                  id = "check"
                  name = "main"
                  onChange={handleChange}
                  value={main}
                />
              }
              label="Главная фотография"
            />
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
        >
          <Grid xs={12}>
            <TextField
              className={useStyles.textField}
              defaultValue={descriptionImage}
              id="descriptionImage"
              label="Описание для картинки"
              margin="normal"
              multiline
              name = "descriptionImage"
              onChange={handleFieldChange}
              required
              rows="4"
              style={{width: '100%',float: 'left'}}
              value = {descriptionImage}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          className={classes.saveButton}
          onClick = {changeDataImage}
          type="submit"
          variant="contained"
        >
        Изменить данные
        </Button>
      </CardActions>
    </Card>
  );
};

CustomerInfo.propTypes = {
  changeDataImage: PropTypes.function,
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
  descriptionImage: PropTypes.string,
  index: PropTypes.number,
  main: PropTypes.boolean,
  photos: PropTypes.array,
  setDescriptionImage: PropTypes.function,
  setMain: PropTypes.function,
  setPhotos: PropTypes.function
};

export default CustomerInfo;

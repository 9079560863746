import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import AvatarEditor from 'react-avatar-editor';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import MaiIcon from '@material-ui/icons/MailOutline';

import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import {config} from 'config';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  sendButton: {

  },
  mailIcon: {
    marginRight: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(2)
  },
  cell: {
    padding: theme.spacing(1)
  }
}));
const Foto = props => {
  const { customer, className, ...rest } = props;

  const classes = useStyles();

  const [editor, setEditor] = useState();

  const setEditorRef = (editor) => {
    setEditor(editor);
  }

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader title={`Фото ${(customer.brandImage ? '' : '(отсутствует)')}`} />
        <Divider />
        <CardContent className={classes.content}>
          <AvatarEditor
            color={[255, 255, 255, 0.6]} // RGBA
            image={customer.brandImage!='undefined' && customer.brandImage!=null ?  '/' + customer.brandImage : '/images/avatars/DefaultAvatar.jpg'}
            ref={(ref) => setEditorRef(ref)}
          />
          { customer.id!==1 &&
      <Button
        component={RouterLink}
        to = {'/images/' + customer.id + '/summary'}
      >
        <EditIcon className={classes.sendButton} />
      Редактировать фотографии
      </Button>
          }
        </CardContent>
      </Card>
    </div>
  );
};

Foto.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

export default Foto;

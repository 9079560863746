// --- Post bootstrap -----
import React, { useState, useEffect } from 'react';
import ProductCategories from 'modules/views/ProductCategories';
import ProductSmokingHero from 'modules/views/ProductSmokingHero';
import AppFooter from 'modules/views/AppFooter';
import ProductHero from 'modules/views/ProductHero';
import ProductValues from 'modules/views/ProductValues';
import ProductHowItWorks from 'modules/views/ProductHowItWorks';
import ProductCTA from 'modules/views/ProductCTA';
import ProductMap from 'modules/views/ProductMap';
import AppAppBar from 'modules/views/AppAppBar';
import Page  from 'components/Page';

import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {getItem,getProductsChildrenMain} from 'admin/Service/Service';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(theme => ({

  footer: {
    backgroundColor: theme.palette.background.default
  },
}));


const Home = props => {

  const classes = useStyles();
  const [mounted, setMounted] = useState(false);
  const [images,setImages] = useState([]);

  useEffect(() => {
    let m = true;
    const fetchProfile = () => {
      getProductsChildrenMain(1,setMounted,updateProductsChildren);
    };

    setMounted(false);
    fetchProfile();

    return () => {
      m = false;
    };

  }, []);

  const updateProductsChildren = (products) =>{
    var img = [];
    products.forEach((item, i) => {
      img.push({
        id: item.id,
        img: '/' + item.brandImage,
        title: item.name,
        width: '40%'
      });
    });
    setImages(img);
  }

  return (
    <Page
      className={classes.root}
      title="Реставрационные, художественные, столярные работы на заказ"
    >
      <Helmet>
        <title>Реставрационные, художественные, столярные работы на заказ</title>
        <meta
          content="Работы по дереву, мебель, реставрация на заказ"
          name="Description"
        />
      </Helmet>
      <AppAppBar page = "home"/>
      <ProductHero />
      <ProductValues />
      {mounted &&
      <ProductCategories
        images = {images}
        setImages = {setImages}
      />
      }
      <ProductCTA />
      <ProductMap />
      <AppFooter className={classes.footer}/>
    </Page>
  );
};

Home.propTypes = {
  className: PropTypes.string
};

export default Home;

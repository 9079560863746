import React, { useState, useEffect } from 'react';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import {Button} from '@material-ui/core';
import {getEmails} from 'admin/Service/Service';
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';


const ExelImport = props => {
  const [mounted, setMounted] = useState(false);
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    let m = true;
    const fetchProfile = () => {
      getEmails(setMounted,setEmails);
    };
    fetchProfile();

    return () => {
      m = false;
    };

  }, []);

  return (
    <ExcelFile
      element={
        <IconButton
          aria-label="Email-адреса"
          color="primary"
          component="span"
        >
          <EmailIcon />
        </IconButton>}
    >
      <ExcelSheet
        data={emails}
        name="emails"
      >
        <ExcelColumn
          label="Email"
          value="email"
        />
        <ExcelColumn
          label="ФИО/Организация"
          value="fio"
        />
        <ExcelColumn
          label="Сообщение"
          value="message"
        />
      </ExcelSheet>
    </ExcelFile>
  );

}


export default ExelImport;

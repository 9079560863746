import React, { useState,useEffect,useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import  FilesDropzone  from 'admin/FilesDropzone';
import InputMask from 'react-input-mask';
import {EditorState,ContentState, Modifier,RichUtils} from 'draft-js';
import { MuiThemeProvider,createMuiTheme } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  Switch,
  TextField,
  Typography,
  ButtonGroup,
  Chip,
  colors
} from '@material-ui/core';
import {renderRedirect,getItem} from 'admin/Service/Service';
import { DatePicker } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayOutlined';
import moment from 'moment';
import AvatarEditor from 'react-avatar-editor';
import DoneIcon from '@material-ui/icons/Done';
import SearchIcon from '@material-ui/icons/Search';
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';
import {SuccessSnackbar, ErrorSnackbar,InfoSnackbar,WarningSnackbar} from 'admin/Snackbar';
import {Service} from 'admin/Service/Service';
import {config} from 'config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles(theme => ({
  root: {
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  card: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  others: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-end'
  },
  chip:{
    color: colors.blue[900],
    backgroundColor: theme.palette.white,
    border: '1px solid #2979ff',
    '&:hover': {
      backgroundColor: theme.palette.white
    }
  },
  chipCheck:{
    color: theme.palette.white ,
    backgroundColor: '#2979ff',
    border: '1px solid #2979ff',
    '&:focus': {
      color: theme.palette.white,
      backgroundColor: '#2979ff',
    },
    '&:hover': {
      color: theme.palette.white,
      backgroundColor: '#2979ff'
    },
    '&:active':{
      color: theme.palette.white,
      backgroundColor: '#2979ff'
    }
  }
}));

const themeChips = createMuiTheme({
  palette: {
    secondary: {
      light: colors.blue[900],
      main: colors.blue[900],
      dark: colors.blue[900]
    }
  },
});

const GeneralSettings = props => {
  const { profile, className,id,edit,classificator,customer, ...rest } = props;

  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarInfo, setOpenSnackbarInfo] = useState(false);
  const [openSnackbarError, setOpenSnackbarError] = useState(false);
  const [openSnackbarWarning, setOpenSnackbarWarning] = useState(false);
  const [message, setMessage] = useState('');
  const [blocking, setBlocking] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSnackbarInfoClose = () => {
    setOpenSnackbarInfo(false);
  };
  const handleSnackbarErrorClose = () => {
    setOpenSnackbarError(false);
  };
  const handleSnackbarWarningClose = () => {
    setOpenSnackbarWarning(false);
  };

  const [values, setValues] = useState({
    name: profile && profile.name ? profile.name : '',
    section: profile && profile.mainProductId ? profile.mainProductId : (classificator.length>0 ? classificator[0] : 0),
    description: profile && profile.shortDescription ? profile.shortDescription : '',
    show:  profile && profile.show ? profile.show: false,
    title:  profile && profile.title ? profile.title: '',
    meta:  profile && profile.meta ? profile.meta: ''
  });

  const [redirect, setRedirect] = useState(false);

  const [editor, setEditor] = useState();
  const [format, setformat] = useState('.png');
  const editorRef = useRef(null);
  const [mounted, setMounted] = useState(false);

  const sectionEditFinish = () => {
    setBlocking(false);
  }

  const editSection = () => {
    var name = values.name;
    var section = values.section;

    var title = values.title;
    var meta = values.meta;

    var re_n = '\n';
    var description = values.description!=null ? values.description.replace(re_n, '</br>'): null;

    var show = values.show;

    if(name == null || name == ''){
      setMessage('Не задано наименование раздела');
      setOpenSnackbarError(true);
      return;
    }

    if(section == null || section == 0){
      setMessage('Не задан родительский раздел');
      setOpenSnackbarInfo(true);
      return;
    }

    if(description == null || description == ''){
      setMessage('Необходимо добавить описание раздела');
      setOpenSnackbarError(true);
      return;
    }

    if(title == null || title == ''){
      setMessage('Добавте заголовок для SEO!');
      setOpenSnackbarError(true);
      return;
    }

    if(meta == null || meta == ''){
      setMessage('Добавте описание для SEO!');
      setOpenSnackbarError(true);
      return;
    }

    let data = (id ? {
      'id': id,
      'name': name,
      'shortDescription': description,
      'mainProductId': section,
      'show' : show,
      'title' : title,
      'meta' : meta,
    } :
      {
        'id': null,
        'name': name,
        'shortDescription': description,
        'mainProductId': section,
        'show' : show,
        'title' : title,
        'meta' : meta,
        'image':{imageBase64:img,description:descriptionImage}
      });
    console.log(JSON.stringify(data));

    setBlocking(true);
    edit(id,data,sectionEditFinish);
  };

  const handleFieldChange = e => {
    e.persist();
    setValues(values => ({
      ...values,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value
    }));
  };



  const handleFieldDescriptionChange = e => {
    e.persist();
    let value = e.target.value;
    setValues(values => ({
      ...values,
      'description': value
    }));
  }

  useEffect(() => {
    let mounted = false;
    console.log('GeneralSettings');
    const getfile = () => {
      setBlocking(false);
      setMounted(true);
    };
    setBlocking(true);
    setMounted(false);
    getfile();

    return () => {
      mounted = true;
    };
  }, []);

  const addFoto = (files) => {
    var newItem = files[0];
    setFile(newItem);
  };

  const deleteFoto = () => {
    setFile(null);
  };

  const getPrew = () => {
    if(file==null){
      return 'delete_image';
    }
    else{
      try {
        return editor!=='undefined' && editor!=null ? editor.getImage().toDataURL(): null;
      } catch (e) {
        return null;
      } finally {

      }
    }
  };


  const handleChange = event => {
    event.persist();

    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const handleSubmit = event => {

    event.preventDefault();
    //  console.log("id: " + id);
    //var preview = getPrew();
    //  console.log(preview);
    editSection();
  };


  return (
    <div>
      <BlockUi
        blocking={blocking}
        style= {{width:'100%',height: '100%',zIndex:'100000000000000000'}}
        tag="div"
      >
        <form onSubmit={handleSubmit}>
          <Card
            {...rest}
            className={clsx(classes.root, className)}
          >
            {renderRedirect()}
            <CardHeader title="Сведения"/>
            <Divider />
            {mounted && (
              <CardContent
                className={classes.card}
                style = {{marginTop:'10px'}}
              >
                <Grid
                  container
                  spacing={4}
                >
                  <Grid
                    className={classes.dates}
                    item
                    lg={6}
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={4}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.show}
                            color="primary"
                            id = "show"
                            name = "show"
                            onChange={handleFieldChange}
                            value={values.show}
                          />
                        }
                        label="Показать раздел на сайте"
                        style={{marginBottom:10}}
                      />
                      <TextField
                        fullWidth
                        label="Родительский раздел"
                        name="section"
                        onChange={handleFieldChange}
                        required
                        style = {{marginBottom:'20px'}}
                        value = {values.section}
                        variant="outlined"
                        select
                        // eslint-disable-next-line react/jsx-sort-props
                        SelectProps={{ native: true }}
                      >
                        {classificator.map((sc,i) => (
                          <option
                            key={sc.id}
                            value={sc.id}
                          >
                            {sc.name}
                          </option>
                        ))}
                      </TextField>
                      <TextField
                        fullWidth
                        fullWidth
                        label="Наименование"
                        name="name"
                        onChange={handleFieldChange}
                        required
                        style = {{marginBottom:'20px'}}
                        type="text"
                        value = {values.name}
                        variant="outlined"
                      />
                      <TextField
                        className={useStyles.textField}
                        fullWidth
                        fullWidth
                        label="Заголовок для SEO"
                        name="title"
                        onChange={handleFieldChange}
                        required
                        style = {{marginBottom:'20px'}}
                        type="text"
                        value = {values.title}
                        variant="outlined"
                      />
                      <TextField
                        className={useStyles.textField}
                        fullWidth
                        fullWidth
                        label="Описание для SEO"
                        name="meta"
                        onChange={handleFieldChange}
                        required
                        style = {{marginBottom:'20px'}}
                        type="text"
                        value = {values.meta}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid style={{marginTop:'20px',height:'120px'}}>
                  <TextField
                    className={useStyles.textField}
                    defaultValue={values.description}
                    id="description"
                    label="Описание раздела"
                    margin="normal"
                    multiline
                    onChange={handleFieldDescriptionChange}
                    required
                    rows="4"
                    style={{width: '100%',float: 'left'}}
                    value = {values.description}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                />
              </CardContent>
            )}
            <Divider />
            <CardActions>
              <Button
                className={classes.saveButton}
                type="submit"
                variant="contained"
              >
                {!id ? ['Добавить'].map(it => (
                  it
                )):['Редактировать'].map(it => (
                  it
                )) }
              </Button>
            </CardActions>
            <SuccessSnackbar
              message = {message}
              onClose={handleSnackbarClose}
              open={openSnackbar}
            />
            <InfoSnackbar
              message = {message}
              onClose={handleSnackbarInfoClose}
              open={openSnackbarInfo}
            />
            <ErrorSnackbar
              message = {message}
              onClose={handleSnackbarErrorClose}
              open={openSnackbarError}
            />
            <WarningSnackbar
              message = {message}
              onClose={handleSnackbarWarningClose}
              open={openSnackbarWarning}
            />
          </Card>
        </form>
        <Service />
      </BlockUi>
    </div>
  );
};

GeneralSettings.propTypes = {
  className: PropTypes.string,
  classificator: PropTypes.array.isRequired,
  edit: PropTypes.func.isRequired,
  id: PropTypes.number,
  profile: PropTypes.object,
};

export default GeneralSettings;

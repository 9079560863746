import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import { Button } from '@material-ui/core';

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(0),
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  loveText:{
    textTransform: 'uppercase',
    fontFamily: 'SynodDisplay',
    color: theme.palette.primary.contrastText
  },

  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  images: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  imageWrapper: {

    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: 100,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
});

function ProductCategories(props) {
  const { classes,images,setImages} = props;

  return (
    <section className={classes.root}>
      <Container
        className={classes.root}
        component="container"
      >
        <Typography
          align="center"
          className={classes.loveText}
          component="h2"
          marked="center"
          variant="h4"
        >
        Для Вас с Любовью...
        </Typography>
        <div className={classes.images}>
          {images.map(image => (
            <ButtonBase
              className={classes.imageWrapper}
              key={image.title}
              style={{
                width: image.width,
              }}
            >
              <div
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${image.img})`,
                }}
              />
              <div className={classes.imageBackdrop} />
              <Button
                className={classes.imageButton}
                component="a"
                href={'/views/' + image.id}
              >
                <Typography
                  className={classes.imageTitle}
                  color="inherit"
                  component="h3"
                  variant="h6"
                >
                  {image.title}
                  <div className={classes.imageMarked} />
                </Typography>
              </Button>
            </ButtonBase>
          ))}
        </div>
      </Container>
    </section>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
  images: PropTypes.array,
  setImages: PropTypes.function
};

export default withStyles(styles)(ProductCategories);

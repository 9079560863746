import React, { useState, useCallback  } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import AvatarEditor from 'react-avatar-editor';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid
} from '@material-ui/core';
import MaiIcon from '@material-ui/icons/MailOutline';

import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Gallery from 'react-photo-gallery';
import SelectedImage from './components/SelectedImage';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Photo from './components/Photo';
import  FilesDropzone  from 'admin/FilesDropzone';
import { addEditImage} from 'admin/Service/Service';
import { deleteImages} from 'admin/Service/Service';
import { saveSort} from 'admin/Service/Service';
import {SuccessSnackbar, ErrorSnackbar,InfoSnackbar,WarningSnackbar} from 'admin/Snackbar';
import {config} from 'config';
import ReactFileReader from 'react-file-reader';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  sendButton: {
    marginTop: theme.spacing(2)
  },
  mailIcon: {
    marginRight: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(2)
  },
  cell: {
    padding: theme.spacing(1)
  }
}));

const Foto = props => {
  const { customer, className,updateInages,productId,setPhotos,photos,selectImage, ...rest } = props;

  const SortablePhoto = SortableElement( props => <SelectedImage
    index={props.item.index}
    key={props.key}
    left={props.left}
    margin={'2px'}
    photo={props.item.item}
    photos={photos}
    selectImage = {selectImage}
    selected = {props.item.selected || selectAll}
    setPhotos={setPhotos}
    top={props.top}
  />);
  const SortableGallery = SortableContainer(({ items,scrollPosition }) => (
    <Gallery
      photos={items}
      renderImage={useCallback(
        ({ index, left, top, key, photo}) => (
          <SortablePhoto
            index = {index}
            item = {{index:index,left: left,top:top,key:key,item:photo}}
            key = {key}
            left = {left}
            top = {top}
          />
        ),  [selectAll])
      }
    />
  ));

  const [selectAll, setSelectAll] = useState(false);
  const [file, setFile] = useState(null);
  const [blockSort, setBlockSort] = useState(false);

  const toggleSelectAll = () => {
    photos.forEach((item, i) => {
      item.selected = !selectAll;
    });
    setPhotos(photos);
    setSelectAll(!selectAll);
  };

  const deleteFoto = () => {

  }

  const selectFoto = (files) =>{
    var newItem = files[0];
    setFile(newItem);
    setAddImg(true);
  }


  const classes = useStyles();

  const [editor, setEditor] = useState();

  const [count, setCount] = useState(0);
  const [addImg, setAddImg] = useState(false);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [zoom, setZoom] = useState(100);

  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarInfo, setOpenSnackbarInfo] = useState(false);
  const [openSnackbarError, setOpenSnackbarError] = useState(false);
  const [openSnackbarWarning, setOpenSnackbarWarning] = useState(false);
  const [width, setWidth] = useState(250);
  const [height, setHeight] = useState(250);
  const [imgInfo, setImgInfo] = useState(null);




  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSnackbarInfoClose = () => {
    setOpenSnackbarInfo(false);
  };
  const handleSnackbarErrorClose = () => {
    setOpenSnackbarError(false);
  };
  const handleSnackbarWarningClose = () => {
    setOpenSnackbarWarning(false);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log('onSortEnd');
    if(oldIndex!==newIndex){
      var ph = arrayMove(photos, oldIndex, newIndex);
      setPhotos(ph);
    }else{
      photos[oldIndex].selected = !photos[oldIndex].selected;
      selectImage(photos[oldIndex],!photos[oldIndex].selected);
    }
  };

  const getCheck = () =>{
    var check = false;
    photos.forEach((item, i) => {
      if(item.selected){
        check = true;
      }
    });
    return check;
  }

  const setEditorRef = (editor) => {
    setEditor(editor);

  }

  const addImage = () =>{
    setAddImg(!addImg);
  }


  function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
  }

  const addFoto = () =>{
    console.log('add');
    var img =  getPrew();
    if(img == null || img == '' || img === 'delete_image'){
      setMessage('Выберите картинку для добавления!');
      setOpenSnackbarInfo(true);
      return;
    }
    const fd = new FormData();
    fd.append('img',file);
    console.log('imgReal');
    addEditImage({imageBase64:img,img:fd,productId:productId,main:false,width:width,height:height},updateInages);
    setFile(null);
    setImgInfo(null);
    setAddImg(false);
  }

  const getPrew = () => {
    if(file==null){
      return 'delete_image';
    }
    else{
      try {
        return editor!=='undefined' && editor!=null ? editor.getImage().toDataURL(): null;
      } catch (e) {
        return null;
      } finally {

      }
    }
  };

  const cancelImage = () =>{
    setFile(null);
    setAddImg(false);
  }

  const onLoadSuccess = (imgInfo) =>{
    setWidth(imgInfo.width);
    setHeight(imgInfo.height);
    setImgInfo(imgInfo);
  }

  const saveSortImages = () =>{
    var sort = '';
    photos.forEach((item, i) => {
      sort+= '' + item.id;
      if(i<photos.length-1){
        sort+= ','
      }
    });
    console.log(sort);
    setBlockSort(true);
    saveSort(sort,productId,afterSaveSort);
  }

  const afterSaveSort = (result) =>{
    setBlockSort(false);
    setMessage( 'Порядок фотографий сохранен.');
    setOpenSnackbar(true);
  }

  const deleteImage = () =>{
    var selectedImages = [];
    var mainImageId = null;
    photos.forEach((item, i) => {
      if(item.selected){
        selectedImages.push(item.id);
      }
      if(item.main){
        mainImageId = item.id;
      }
    });
    if(selectedImages.length>0){
      var main = false;
      photos.forEach((item, i) => {
        console.log(item.selected + ' ' + item.main);
        if(item.selected && item.main){
          setMessage('Основное изображение удалить нельзя! Оно привязано к разделу. Поменяйте основное изображение или удалите раздел!');
          setOpenSnackbarInfo(true);
          main = true;
          return;
        }
      });
      if(!main){
        deleteImages({images:selectedImages,mainImageId:mainImageId},updateInages);
      }
    }else{
      setMessage('Выберите картинку для удаления!');
      setOpenSnackbarInfo(true);
    }
  }

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <p>
          <Button onClick={(e)=>addImage()}>{!addImg ? 'добавить изображение' : 'закрыть добавление'}</Button>
          {
            <Button onClick={deleteImage}>удалить</Button>
          }
          { !addImg && !blockSort &&
          <Button onClick={(e)=>{saveSortImages()}}>coхранить порядок</Button>
          }
        </p>

        { addImg && !file &&
          <FilesDropzone
            addFoto = {selectFoto}
            deleteFoto = {deleteFoto}
          />
        }
        { addImg && file &&
          <div>
            <AvatarEditor
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              height = {height}
              id = "avatarEditor"
              image={file!='undefined' && file!=null ? (file.name!='undefined' && file.name!=null ? window.URL.createObjectURL(file): file): '/images/avatars/DefaultAvatar.png'}
              onLoadSuccess = {onLoadSuccess}
              ref={(ref) => setEditorRef(ref)}
              rotate={rotate}
              scale={scale}
              width = {width}
            />
            <div>
              <p>
                <Button onClick={(e)=>addFoto()}>Добавить</Button>
                <Button onClick={(e)=>cancelImage()}>Отмена</Button>
              </p>
            </div>
          </div>
        }

      </Card>
      { !addImg &&
    <SortableGallery
      axis={'xy'}
      items={photos}
      onSortEnd={onSortEnd}
    />
      }
      <SuccessSnackbar
        message = {message}
        onClose={handleSnackbarClose}
        open={openSnackbar}
      />
      <InfoSnackbar
        message = {message}
        onClose={handleSnackbarInfoClose}
        open={openSnackbarInfo}
      />
      <ErrorSnackbar
        message = {message}
        onClose={handleSnackbarErrorClose}
        open={openSnackbarError}
      />
      <WarningSnackbar
        message = {message}
        onClose={handleSnackbarWarningClose}
        open={openSnackbarWarning}
      />
    </div>
  );
};

Foto.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
  photos: PropTypes.array,
  productId: PropTypes.number,
  selectImage: PropTypes.function,
  setPhotos: PropTypes.function,
  updateInages: PropTypes.function
};

export default Foto;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import $ from 'jquery';

const Checkmark = ({ selected }) => (
  <div
    style={
      selected
        ? { left: '4px', top: '4px', position: 'absolute', zIndex: '1' }
        : { display: 'none' }
    }
  >
    <svg
      height="24px"
      style={{ fill: 'white', position: 'absolute' }}
      width="24px"
    >
      <circle
        cx="12.5"
        cy="12.2"
        r="8.292"
      />
    </svg>
    <svg
      height="24px"
      style={{ fill: '#06befa', position: 'absolute' }}
      width="24px"
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  </div>
);

const CheckmarkMain = ({ selected }) => (
  <div
    style={
      selected
        ? {right: '30px',bottom: '30px', position: 'absolute', zIndex: '1' }
        : { display: 'none' }
    }
  >
    <svg
      height="24px"
      style={{ fill: 'white', position: 'absolute' }}
      width="24px"
    >
      <circle
        cx="12.5"
        cy="12.2"
        r="8.292"
      />
    </svg>
    <svg
      height="24px"
      style={{ fill: 'green', position: 'absolute' }}
      width="24px"
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  </div>
);

const imgStyle = {
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s'
};
const selectedImgStyle = {
  transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s'
};
const cont = {
  backgroundColor: '#eee',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative'
};

const SelectedImage = ({
  index,
  onClick,
  photo,
  margin,
  direction,
  top,
  left,
  selected,
  photos,
  setPhotos,
  selectImage,
}) => {
  //calculate x,y scale
  const sx = (100 - (30 / photo.width) * 100) / 100;
  const sy = (100 - (30 / photo.height) * 100) / 100;
  selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

  const [isP, setIsP] = useState(selected);
  const [checkMain, setCheckMain] = useState(photo.main);

  if (direction === 'column') {
    cont.position = 'absolute';
    cont.left = left;
    cont.top = top;
  }

  const handleOnClick = e => {
    console.log('e');
    var m_selected = photos[index].selected;
    photos[index].selected = !m_selected;
    setPhotos(photos);
    selectImage(photos[index],!m_selected);
    setIsP(!m_selected);
    onClick(event, { photo, index });
  };

  const handleChange = e => {
    setCheckMain( photos[index].main);
  };

  useEffect(() => {
    setIsP(isP);
  }, [isP]);

  return (
    <div
      className={!photos[index].selected || !isP   ? 'not-selected' : ''}
      id={photo.id}
      onClick={handleChange}
      style={{ margin, height: photo.height, width: photo.width, ...cont }}
    >
      <Checkmark selected={photos[index].selected || isP   ? true : false} />
      <img
        alt={photo.title}
        style={
          photos[index].selected  || isP ? { ...imgStyle, ...selectedImgStyle } : { ...imgStyle }
        }
        {...photo}
        onClick={handleOnClick}
      />
      <CheckmarkMain selected={photos[index].main || checkMain  ? true : false} />
      <style>{'.not-selected:hover{outline:2px solid #06befa}'}</style>
    </div>
  );
};

SelectedImage.propTypes = {
  direction: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  margin: PropTypes.number.isRequired,
  photo: PropTypes.object.isRequired,
  photos: PropTypes.array.isRequired,
  selectImage: PropTypes.function,
  selected: PropTypes.boolean,
  setPhotos: PropTypes.function,
  top: PropTypes.number.isRequired
};

export default SelectedImage;

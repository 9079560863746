import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

import axios from 'utils/axios';
import { CustomerInfo, Foto } from './components';
import {Button} from '@material-ui/core';
import {Hidden} from '@material-ui/core';
import $ from 'jquery';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Summary = props => {
  const { className,customer,count,setCount,updateInages,productId,photos,setPhotos,descriptionImage, setDescriptionImage,changeDataImage,setMain,main, ...rest } = props;

  const [check, setCheck] = useState(false);
  const [index, setIndex] = useState(0);
  const classes = useStyles();

  if (!customer) {
    return null;
  }

  useEffect(() => {
    let m = true;
    var count = 0;
    var mindex = 0;
    photos.forEach((item, i) => {
      if(item.selected){
        count = count+1;
        mindex = i;
      }
    });
    if(count===1){
      setDescriptionImage(photos[mindex].shortDescription);
      setMain(photos[mindex].main);
      setIndex(mindex);
    }
    setCheck(count===1);
    return () => {
      m = false;
    };

  }, []);

  const selectImage =(image,selected)=>{
    console.log('RRR');
    var count = 0;
    var mindex = 0;
    photos.forEach((item, i) => {
      if(item.selected){
        count = count+1;
        mindex = i;
      }
    });
    if(count===1){
      setDescriptionImage(photos[mindex].shortDescription);
      setMain(photos[mindex].main);
      setIndex(mindex);
    }
    setCheck(count===1);
  }



  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
      xs={12}
    >
      {photos && check &&
          <Grid
            item
            xs={12}
          >
            <CustomerInfo
              changeDataImage = {changeDataImage}
              customer={customer}
              descriptionImage = {descriptionImage}
              index = {index}
              main = {main}
              photos = {photos}
              setDescriptionImage = {setDescriptionImage}
              setMain = {setMain}
              setPhotos = {setPhotos}
            />
          </Grid>
      }

      {photos &&
        <Grid
          item
          xs={12}
        >
          <Foto
            count = {count}
            customer={customer}
            photos = {photos}
            productId = {productId}
            selectImage = {selectImage}
            setCount = {setCount}
            setPhotos = {setPhotos}
            updateInages = {updateInages}
          />
        </Grid>
      }
    </Grid>
  );
};

Summary.propTypes = {
  changeDataImage:  PropTypes.function,
  className: PropTypes.string,
  count: PropTypes.number,
  customer: PropTypes.object,
  descriptionImage: PropTypes.string,
  main: PropTypes.boolean,
  photos: PropTypes.array,
  productId: PropTypes.number,
  setCount:PropTypes.function,
  setDescriptionImage: PropTypes.function,
  setMain: PropTypes.function,
  setPhotos: PropTypes.function,
  updateInages: PropTypes.function
};

export default Summary;

import { userConstants } from '../_constants';

export function dataproject(state = {}, action) {
  switch (action.type) {
    case 'message':
      return {
        message: action.message
      };
    case 'status':
      return {
        error: action.error
      };
    case 'info':
      return {
        info: action.info
      };
    case 'redirect':
      return {
        redirect: action.redirect
      };
    case 'mounted':
      return {
        mounted: action.mounted
      };
    case 'data':
      return {
        data: action.data
      };
    case 'success':
      return {
        success: action.success
      };
    case 'info':
      return {
        info: action.info
      };
    case 'error':
      return {
        error: action.error
      };
    case 'warning':
      return {
        warning: action.warning
      };
    case 'pagination':
      return {
        warning: action.pagination
      };
    default:
      return state
  }
}

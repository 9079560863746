import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import {config} from 'config';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { Grid } from '@material-ui/core';
import {Button} from '@material-ui/core';
import BlockUi from 'react-block-ui';
import { Page } from 'components';
import { Header, Summary} from './components';
import {
  handleResponse,
  getImages,
  getItem,
  initRepository,
  setMessage,
  setStatus,
  setRedirect,
  getStorePhotos,
  addEditImageInfo,
  getProductOnly
} from 'admin/Service/Service';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor:  theme.palette.background.default
  },
  tabs: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.contrastText,
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Galary = props => {
  const { match, history } = props;
  const classes = useStyles();
  const { id, tab } = match.params;
  const [mounted, setMounted] = useState(false);
  const [count, setCount] = useState(1);
  const [descriptionImage, setDescriptionImage] = useState(null);
  const [main, setMain] = useState(null);
  const [product, setProduct] = useState(null);
  const [photos,setPhotos] = useState([
    {
      src: 'https://source.unsplash.com/2ShvY8Lf6l0/800x599',
      width: 1,
      height: 1,
      selected:false
    },
    {
      src: 'https://source.unsplash.com/Dm-qxdynoEc/800x799',
      width: 1,
      height: 1,
      selected:false
    }
  ]);

  useEffect(() => {
    let m = true;
    initRepository();
    const fetchProfile = () => {
      updateInages();
    };
    fetchProfile();

    return () => {
      m = false;
    };

  }, []);


  const updateInages = ()=>{
    setMounted(false);
    getImages(id,setMounted,afterGetImages);
  }

  const afterGetImages = (images)=>{
    getProductOnly(id,afterProduct,images);
  }

  const afterProduct = (productResult,images)=>{
    console.log('productResult');
    console.log(productResult);
    console.log('images');
    console.log(productResult);
    updatePhotos(images,productResult);
  }

  const sortForSortProduct = (productResult,images) =>{
    var sortedIds = productResult.sort!=undefined && productResult.sort!='undefined' && productResult.sort!=null && productResult.sort!='' ? (productResult.sort + '').split(','):[];
    var newImages = [];
    if(images!=null && images!='undefined' && images!=undefined && sortedIds.length==images.length){
      sortedIds.forEach((itemId, i) => {
        newImages.push(images.find(item => item.id === Number(itemId)));
      })
    }
    return newImages.length>0 ? newImages : ((images!=null && images!='undefined' && images!=undefined) ? images : []);
  }

  const updatePhotos = (images,productResult) =>{
    var ph = [];
    console.log('UUU');

    var oldImages = getStorePhotos();
    images = sortForSortProduct(productResult,images);
    images.forEach((item, i) => {
      var newItem = oldImages && oldImages.length>0 ? oldImages.find(t => t.id === item.id): null;
      console.log(newItem);
      if(newItem!=null){
        ph.push({
          id: newItem.id,
          src: newItem.src,
          small: item.small,
          width: item.width!=null ? item.width: 1,
          height: item.height!=null ? item.height: 1,
          selected:newItem.main,
          shortDescription: newItem.shortDescription,
          main: newItem.main
        });
      }else{
        console.log('item');
        console.log(item);
        ph.push({
          id: item.id,
          src: '/' + item.img,
          small: item.small,
          width: item.width!=null ? item.width: 1,
          height: item.height!=null ? item.height: 1,
          selected:item.main,
          shortDescription: item.description,
          main: item.main
        });
      }
    });
    console.log(ph);
    setPhotos(ph);
    setProduct(productResult);
    console.log('[setMounted]');
    console.log(mounted);
  }

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'summary', label: 'Информация' }
  ];

  if (!tab) {
    return <Redirect to={`/section/${id}/summary`} />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  const changeDataImage = e =>{
    console.log('ttt');
    var data = [];
    photos.forEach((item, i) => {
      data.push({
        id: item.id,
        img: item.src && item.src.length>0 ? item.src.slice(1):null,
        small: item.small,
        productId: id,
        description: item.shortDescription,
        main: item.main,
        width: item.width,
        height: item.height,
      });
    });
    addEditImageInfo(data,updateInages);
  }

  return (
    <Page
      className={classes.root}
      title="Информация о разделе"
    >
      <BlockUi
        blocking={!mounted}
        style= {{width:'100%',height: '100%',zIndex:'100000000000000000'}}
        tag="div"
      >
        {mounted && getItem('data')!=null &&  [1].map(it => (
          <div key = "1">
            <div>
              <Header customer = {getItem('data')}/>
              <Tabs
                className={classes.tabs}
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={tab}
                variant="scrollable"
              >
                {tabs.map(tab => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
                <div style = {{marginLeft:'auto',padding:'10px'}} />
              </Tabs>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.content}>
              {tab === 'summary' &&  <Summary
                changeDataImage = {changeDataImage}
                count = {count}
                customer = {getItem('data2')}
                descriptionImage = {descriptionImage}
                main = {main}
                photos = {photos}
                productId = {id}
                setCount = {setCount}
                setDescriptionImage = {setDescriptionImage}
                setMain = {setMain}
                setPhotos = {setPhotos}
                updateInages = {updateInages}
              />}
            </div>
          </div>
        ))
        }
      </BlockUi>
    </Page>
  );
};


Galary.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default connect (
  (state, props) => ({
    authentication: state.authentication
  }),
  dispatch => ({
    pushMessage: (queue) => {
      dispatch({ type: 'SAVE_QUEUE', payload: queue })
    }
  })
)(Galary);


export  const config = {
  settings: {
    compact: true,
    direction: 'ltr',
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: 'DARK',
  },

  //Local
  // apiUrl: 'http://localhost:8888',
  // base:'test'

  //prod
  apiUrl: 'https://drevo-delo.by/api',
  //apiUrl: 'http://85.209.148.251:8888'
};

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Provider as StoreProvider } from 'react-redux';

import { store } from './authorization/_helpers';

import {App} from './App';
import * as serviceWorker from './serviceWorker';
import {useLocation} from "react-router-dom";


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
ReactDOM.hydrate(<StoreProvider store={store}>
    <App />
</StoreProvider>,
document.getElementById('root')
);
}else{
  ReactDOM.render(
    <StoreProvider store={store}>
        <App />
    </StoreProvider>,
    document.getElementById('root')
  );
}

serviceWorker.unregister();

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import {config} from 'config';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { Grid } from '@material-ui/core';
import {Button} from '@material-ui/core';

import { Page } from 'components';
import { Header, Summary} from './components';
import {
  handleResponse,
  getSection,
  getItem,
  initRepository,
  setMessage,
  setStatus,
  setRedirect,
  setMounted} from 'admin/Service/Service';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor:  theme.palette.background.default
  },
  tabs: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.contrastText,
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  windowDiv: {
    backgroundColor: theme.palette.background.default,

    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
}));

const SectionDetails = props => {
  const { match, history } = props;
  const classes = useStyles();
  const { id, tab } = match.params;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    let m = true;
    initRepository();
    const fetchProfile = () => {

      getSection(id,setMounted)
    };
    setMounted(false);
    fetchProfile();

    return () => {
      m = false;
    };

  }, []);

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'summary', label: 'Информация' }
  ];

  if (!tab) {
    return <Redirect to={`/section/${id}/summary`} />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  return (
    <div className={classes.windowDiv} >
      <Page
        className={classes.root}
        title="Информация о разделе"
      >
        {mounted &&  [1].map(it => (
          <div key = "1">
            <div>
              <Header customer = {getItem('data')}/>
              <Tabs
                className={classes.tabs}
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={tab}
                variant="scrollable"
              >
                {tabs.map(tab => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
                <div style = {{marginLeft:'auto',padding:'10px'}}>
                  {tab === 'summary' && getItem('data').id!==1 && (
                    <Button
                      component={RouterLink}
                      to = {'/editSection/' + getItem('data').id}
                    >
                      <EditIcon className={classes.buttonIcon} />
            Редактировать Раздел
                    </Button>
                  )}
                </div>
              </Tabs>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.content}>
              {tab === 'summary' && <Summary customer = {getItem('data')}/>}
            </div>
          </div>
        ))
        }
      </Page>
    </div>
  );
};


SectionDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default connect (
  (state, props) => ({
    authentication: state.authentication
  }),
  dispatch => ({
    pushMessage: (queue) => {
      dispatch({ type: 'SAVE_QUEUE', payload: queue })
    }
  })
)(SectionDetails);

import React from 'react';
import clsx from 'clsx';
import {config} from 'config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { history } from 'authorization/_helpers';
import {userActions} from 'authorization/_actions';

import axios from 'utils/axios';
import { connect } from 'react-redux';
import {SuccessSnackbar, ErrorSnackbar,InfoSnackbar,WarningSnackbar} from 'admin/Snackbar';

const useStyles = makeStyles(() => ({
  root: {}
}));

export function failedToFetch(error){
  if(error === 'Failed to fetch' || error.message === 'Failed to fetch'){
    console.log('Cервер недоступен!');
    setItem('message', 'Cервер недоступен! Приносим свои извенения. Зайдите заново! ');
    setItem('info', true);
    setItem('status', 10);
    handleLogout();
    setItem('redirect', true);
  }
}

export function handleLogout(){
  userActions.logout();
  history.push('/sign-in');
}

export function handleResponse(response){
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    // console.log(data);
    if (!response.ok) {
      if (response.status === 500 || response.status === 400) {

        setItem('message', 'Произошла ошибка. Приносим свои извенения!');
        setItem('status', response.status);
        setItem('error', true);
      } else if(response.status === 401){
        console.log('Cервер недоступен!');
        setItem('message', 'Произошла ошибка! Сервер недоступен! Приносим свои извенения. ');
        setItem('error', true);
        handleLogout();
        setItem('status', 10);
        setItem('redirect', true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export function setMessage(message){
  setItem('message', message);
}

export function setStatus(status){
  setItem('status', status);
}

export function setItem(name,object){
  localStorage.setItem(name, JSON.stringify(object));
}

export function renderRedirect(redirect){
  if (getItem('redirect')) {
    setItem('redirect',false);
    switch(getItem('status')){
      case '401':
        return <Redirect to="/errors/error-401" />
        break;
      case '404':
        return <Redirect to="/errors/error-404" />
        break;
      case '500':
        return <Redirect to="/errors/error-500" />
        break;
    }
    return <Redirect to="/sign-in" />
  }
}

export function getItem(name){
  switch (name) {
    case 'redirect':
      return (localStorage.getItem(name) === 'true');
    case 'success':
      return (localStorage.getItem(name) === 'true' );
    case 'info':
      return (localStorage.getItem(name) === 'true');
    case 'error':
      return (localStorage.getItem(name) === 'true');
    case 'warning':
      return (localStorage.getItem(name) === 'true');
    case 'message':
      return localStorage.getItem(name)!=null && localStorage.getItem(name)!='null' ? localStorage.getItem(name):'';
    case 'status':
      return localStorage.getItem(name);
    default:
      return JSON.parse(localStorage.getItem(name));
  }
}

export function initRepositoryEx(){
  setItem('dataEx1', null);
  setItem('dataEx2', null);
  setItem('dataEx3', null);
  setItem('photos',null);
  setItem('data2', null);
  setItem('pagination1', null);
  setItem('pagination2', null);
  setItem('pagination3', null);
}

export function setStorePhotos(photos){
  setItem('photos',photos);
}

export function getStorePhotos(){
  return getItem('photos');
}

export function initRepository(){
  setItem('message', null);
  setItem('status', null);
  setItem('info', null);
  setItem('photos',null);
  setItem('redirect', false);
  setItem('data', null);
  setItem('data2', null);
  setItem('dataEx1', null);
  setItem('dataEx2', null);
  setItem('dataEx3', null);
  setItem('pagination1', null);
  setItem('pagination2', null);
  setItem('pagination3', null);
  setItem('success', false);
  setItem('info', false);
  setItem('error', false);
  setItem('warning', false);
  setItem('pagination', null);
}

export function setPagination(data){
  var pagination = {
    'last' : data.last,
    'totalPages' : data.totalPages,
    'totalElements' : data.totalElements,
    'size' : data.size,
    'number' : data.number,
    'numberOfElements' : data.numberOfElements,
    'sort' : {
      'sorted' : data.sort.sorted,
      'unsorted': data.sort.unsorted,
      'empty':data.sort.empty
    },
    'first' : data.first
  }
  setItem('pagination', pagination);

}


////////////////////////////Сервисы/////////////////////////////////////


export function getDataSection(page,size,sort,order,setMounted,search = null){
  setMounted(false);
  var sorting = 'id';
  switch (sort) {
    case 'id':
      sorting = 'id';
      break;
    case 'name':
      sorting = 'name';
      break;
    case 'description':
      sorting = 'shortDescription';
      break;
    case 'main':
      sorting = 'mainProductId';
      break;
    default:
      sorting = 'id';
  }
  sorting+= (order==='desc' ? ',' + 'desc': ',' + 'asc' );

  const searchStr = search!=='undefined' && search!== null && search!==undefined && search!=='' ? '&name=' + search : '';
  const user = getItem('user');
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': 'Bearer ' + user.token
    },
    crossDomain:true
  };
  console.log(`${config.apiUrl}/main/products/all` +
    '?' + 'page=' + page + '&' + 'size=' + size + '&' + 'sort=' + sorting +
          searchStr , requestOptions);

  return fetch(`${config.apiUrl}/main/products/all` +
     '?' + 'page=' + page + '&' + 'size=' + size + '&' + 'sort=' + sorting +
     searchStr, requestOptions).then(handleResponse)
    .then(data => {
      setPagination(data);
      setItem('data', data.content);
      setMounted(true);
    }).catch(response => {
      failedToFetch(response);
    });
}

export function  getImages(id,setMounted,f){
  if(id){
    const user = getItem('user');
    console.log(`${config.apiUrl}/main/images/`+ id);
    //console.log(authentication.user.token);
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      crossDomain:true
    };

    fetch(`${config.apiUrl}/main/images/` + id, requestOptions)
      .then(handleResponse)
      .then(images => {
        setItem('data2', images);
        f(images);
        getSection(id,setMounted);
        return images;
      }).catch(response => {
        failedToFetch(response);
      });
  }else{
    setItem('data',{av:''});
    setMounted(true);
  }
}


export function  getSection(id,setMounted){
  if(id){
    const user = getItem('user');
    console.log(`${config.apiUrl}/main/product/`+ id);
    //console.log(authentication.user.token);
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Token': 'Bearer ' + user.token
      },
      crossDomain:true
    };

    fetch(`${config.apiUrl}/main/product/` + id, requestOptions)
      .then(handleResponse)
      .then(product => {
        setItem('data', product);
        setMounted(true);
        return images;
      }).catch(response => {
        failedToFetch(response);
      });
  }else{
    setItem('data',{av:''});
    setMounted(rf);
  }
}

export function  getProductOnly(id,f,images){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/product/` + id);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': 'Bearer ' + user.token
    },
    crossDomain:true
  };

  fetch(`${config.apiUrl}/main/product/` + id , requestOptions)
    .then(handleResponse)
    .then(product => {
      console.log('product');
      console.log(product);
      f(product,images);
      return product;
    }).catch(response => {
      failedToFetch(response);
    });
}

export function  getProduct(id,setMounted){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/product/` + id);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': 'Bearer ' + user.token
    },
    crossDomain:true
  };

  fetch(`${config.apiUrl}/main/product/` + id , requestOptions)
    .then(handleResponse)
    .then(product => {
      setItem('data', product);
      geClassificatorSection(setMounted);
      return product;
    }).catch(response => {
      failedToFetch(response);
    });
}

export function  getProductsChildren(id,setMounted,updateProductsChildren){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/product/` + id);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    crossDomain:true
  };

  fetch(`${config.apiUrl}/main/products/` + id , requestOptions)
    .then(handleResponse)
    .then(product => {
      setItem('data3', product);
      updateProductsChildren(product);
      setMounted(true);
      return product;
    }).catch(response => {
      failedToFetch(response);
    });
}


export function  getProductsChildrenMain(id,setMounted,updateProductsChildren){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/products_main/` + id);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    crossDomain:true
  };

  fetch(`${config.apiUrl}/main/products_main/` + id , requestOptions)
    .then(handleResponse)
    .then(product => {
      setItem('data3', product);
      updateProductsChildren(product);
      setMounted(true);
      return product;
    }).catch(response => {
      failedToFetch(response);
    });
}


export function  geClassificatorSection(setMounted){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/products`);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': 'Bearer ' + user.token
    },
    crossDomain:true
  };

  fetch(`${config.apiUrl}/main/products` , requestOptions)
    .then(handleResponse)
    .then(products => {
      setItem('data2', products);
      setMounted(true);
      return product;
    }).catch(response => {
      failedToFetch(response);
    });
}

export function  addEditFileInfo(data,f){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/save_file_info`);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': 'Bearer ' + user.token
    },
    crossDomain:true,
    body: JSON.stringify(data)
  };

  return  fetch(`${config.apiUrl}/main/save_file_info/` , requestOptions).then(handleResponse)
    .then(foto => {
      setItem('message', 'Фото успешно добавлено ' + 'id=' + foto.id);
      setItem('status', 1);
      setItem('success',true);
      f();
    }).catch(response => {
      f();
      failedToFetch(response);
    });
}

export function  addEditImage(data,f){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/save_file/`);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Access-Token': 'Bearer ' + user.token
    },
    body: data.img
  };

  return  fetch(`${config.apiUrl}/main/save_file/` , requestOptions).then(handleResponse)
    .then(foto => {
      console.log('addEditImage');
      data.image = foto.img;
      data.imageBase64 = null;
      data.small = foto.small;
      addEditFileInfo(data,f);
    }).catch(response => {
      f();
      failedToFetch(response);
    });
}

export function  getEmails(setMounted,f){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/emails`);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': 'Bearer ' + user.token
    },
    crossDomain:true
  };

  fetch(`${config.apiUrl}/main/emails` , requestOptions)
    .then(handleResponse)
    .then(emails => {
      setMounted(true);
      f(emails);
      return emails;
    }).catch(response => {
      failedToFetch(response);
    });
}

export function  mailing(data,f){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/send_email/`);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    crossDomain:true,
    body: JSON.stringify(data)
  };
  return  fetch(`${config.apiUrl}/main/send_email/`, requestOptions).then(handleResponse)
    .then(message => {
      f(message);
    }).catch(response => {
      f();
      failedToFetch(response);
    });
}

export function deleteSection(id,f){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/delete_section/` + id);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': 'Bearer ' + user.token
    },
    crossDomain:true
  };
  return  fetch(`${config.apiUrl}/main/delete_section/` + id, requestOptions).then(handleResponse)
    .then(section => {
      setItem('message', section.message);
      setItem('status', 1);
      setItem('success',true);
      f();
    }).catch(response => {
      f();
      failedToFetch(response);
    });
}

export function deleteImages(data,f){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/delete_images/`);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': 'Bearer ' + user.token
    },
    crossDomain:true,
    body: JSON.stringify(data)
  };
  return  fetch(`${config.apiUrl}/main/delete_images/`, requestOptions).then(handleResponse)
    .then(section => {
      setItem('message', 'изображения успешно удалены ');
      setItem('status', 1);
      setItem('success',true);
      f();
    }).catch(response => {
      f();
      failedToFetch(response);
    });
}

export function  addEditSectionInfo(id,data,f){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/save_file/`);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Access-Token': 'Bearer ' + user.token
    },
    body: data.image.image
  };

  return  fetch(`${config.apiUrl}/main/save_file/` , requestOptions).then(handleResponse)
    .then(foto => {
      console.log('addEditSectionInfo');
      data.image.image = foto.img;
      data.imageBase64 = null;
      data.image.small = foto.small;
      addEditSection(id,data,f);
    }).catch(response => {
      f();
      failedToFetch(response);
    });
}

export function  addEditSection(id,data,f){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/save_product/`);
  if(data.mainProductId.id){
    data.mainProductId = data.mainProductId.id
  }
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': 'Bearer ' + user.token
    },
    crossDomain:true,
    body: JSON.stringify(data)
  };
  return  fetch(`${config.apiUrl}/main/save_product/`, requestOptions).then(handleResponse)
    .then(section => {
      let mess = id ? 'Раздел успешно отредактирован ' : 'Раздел успешно добавлен';
      setItem('message', mess + 'id=' + section.id + ' наименование: ' + section.name);
      setItem('status', 1);
      setItem('success',true);
      f();
    }).catch(response => {
      f(response);
    });

}

export function  addEditImageInfo(data,f){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/save_image_info/`);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': 'Bearer ' + user.token
    },
    crossDomain:true,
    body: JSON.stringify(data)
  };
  return  fetch(`${config.apiUrl}/main/save_image_info/`, requestOptions).then(handleResponse)
    .then(inf => {
      setItem('message', 'Информация успешно добавлена ');
      setItem('status', 1);
      setItem('success',true);
      f();
    }).catch(response => {
      f();
      failedToFetch(response);
    });

}

export function saveSort(sort,productId,afterSaveSort){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/save_sort/` + productId);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Token': 'Bearer ' + user.token
    },
    crossDomain:true,
    body: JSON.stringify({sort:sort})
  };
  return  fetch(`${config.apiUrl}/main/save_sort/` + productId, requestOptions).then(handleResponse)
    .then(inf => {
      console.log('saveSort');
      setItem('message', 'Порядок фотографий сохранен.');
      setItem('status', 1);
      setItem('success',true);
      afterSaveSort(inf);
    }).catch(response => {
      afterSaveSort();
      failedToFetch(response);
    });
}


export function getStoreMain(id,setMounted,updatePhotos){
  const user = getItem('user');
  console.log(`${config.apiUrl}/main/view_selections/` + id);
  //console.log(authentication.user.token);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    crossDomain:true
  };

  fetch(`${config.apiUrl}/main/view_selections/` + id , requestOptions)
    .then(handleResponse)
    .then(products => {
      setItem('data', products);
      getImages(id==='1' ? products.id: id,setMounted,updatePhotos);
      return product;
    }).catch(response => {
      failedToFetch(response);
    });
}




////////////////////////////КОНЕЦ//////////////////////////////////////




export class Service extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      serviceId: null,
      change:0,
      success:false,
      info:false,
      error:false,
      warning:false
    };

    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.handleSnackbarInfoClose = this.handleSnackbarInfoClose.bind(this);
    this.handleSnackbarErrorClose = this.handleSnackbarErrorClose.bind(this);
    this.handleSnackbarWarningClose = this.handleSnackbarWarningClose.bind(this);
    this.setChange = this.setChange.bind(this);


    var timer = setInterval(function () {
      if(getItem('redirect')){
        this.setChange();

      }
      setTimeout(() => {
        this.setChange();
      }, 5000);

      if(getItem('error')){
        this.setChange();
        setTimeout(() => {
          setItem('error',false);
          this.handleSnackbarErrorClose();
          this.setChange();
        }, 5000);
      }
      if(getItem('warning')){
        this.setChange();
        setTimeout(() => {
          setItem('warning',false);
          this.handleSnackbarWarningClose();
          this.setChange();
        }, 5000);
      }
      if(getItem('info')){
        this.setChange();
        setTimeout(() => {
          setItem('info',false);
          this.handleSnackbarInfoClose();
          this.setChange();
        }, 5000);
      }
      if(getItem('success')){
        this.setChange();
        setTimeout(() => {
          setItem('success',false);
          this.handleSnackbarClose();
          //  this.setChange();
        }, 5000);
      }

    }.bind(this), 3000);
  }

  setChange(){
    this.state.change+=1;
    this.setState({ change: this.state.change });
  }

  handleSnackbarClose(){
    setItem('success', false);
  }

  handleSnackbarInfoClose(){
    setItem('info', false);
  }
  handleSnackbarErrorClose(){
    setItem('error', false);
  }
  handleSnackbarWarningClose(){
    setItem('warning', false);
  }

  componentDidMount(){
    this.refresher = setInterval(1000, () => this.forceUpdate())
  }

  componentWillUnmount () {
    clearInterval(this.refresher)
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {renderRedirect()}
        <SuccessSnackbar
          message = {getItem('message')}
          onClose={this.handleSnackbarClose}
          open={(getItem('success') )}
        />
        <InfoSnackbar
          message = {getItem('message')}
          onClose={this.handleSnackbarInfoClose}
          open={(getItem('info') )}
        />
        <ErrorSnackbar
          message = {getItem('message')}
          onClose={this.handleSnackbarErrorClose}
          open={(getItem('error') )}
        />
        <WarningSnackbar
          message = {getItem('message')}
          onClose={this.handleSnackbarWarningClose}
          open={(getItem('warning'))}
        />
      </div>
    );
  }
}

Service.propTypes = {
  className: PropTypes.string
};

export default Service;

import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import clsx from 'clsx';
import getInitials from 'utils/getInitials';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AvatarEditor from 'react-avatar-editor';
import {
  Card,
  CardContent,
  CardActions,
  Avatar,
  Grid,
  Switch,
  Typography,
  Button,
  TextField
} from '@material-ui/core';
import {config} from 'config';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  name: {
    marginTop: theme.spacing(1)
  },
  avatar: {
    height: 100,
    width: 100
  },
  removeBotton: {
    width: '100%'
  }
}));

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus,&:hover,&$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 11px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

const ProfileDetails = props => {
  const { profile, className,file,editor,setEditor,imgInfo,setImgInfo,deleteFoto,borderRadius,width,height,setWidth,setHeight, ...rest } = props;

  const classes = useStyles();

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [border, setBorder] = useState(50);
  const [zoom, setZoom] = useState(100);

  const widthChange = (e) => {
    setWidth(Number(e.target.value));
  };

  const heightChange = (e) => {
    setHeight(Number(e.target.value));
  };

  const borderChange = (e) => {
    setBorder(Number(e.target.value));
  };

  const scaleChange = (val) => {
    setScale(val/10);
  };

  const rotateChange = () => {
    setRotate(rotate+90>=360? 0 : rotate+90);
    console.log(editor.getImage().toDataURL());
  };

  const setEditorRef = (editor) => {
    setEditor(editor);
  }

  const onLoadSuccess = (imgInfo) =>{
    setWidth(imgInfo.width);
    setHeight(imgInfo.height);
    setImgInfo(imgInfo);
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <AvatarEditor
          border={border}
          color={[255, 255, 255, 0.6]} // RGBA
          height={height}
          image={file!='undefined' && file!=null ? (file.name!='undefined' && file.name!=null ? window.URL.createObjectURL(file): file): `${config.baseUrl}/images/avatars/DefaultAvatar.png`}
          onLoadSuccess = {onLoadSuccess}
          ref={(ref) => setEditorRef(ref)}
          rotate={rotate}
          scale={scale}
          width={width}
        />
        <Grid
          container
          spacing={4}
          style = {{marginTop:'20px'}}
        >
          <IconButton
            aria-label="delete"
            onClick = {deleteFoto}
          >
            <DeleteIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid
          container
          spacing={4}
          style = {{marginTop:'20px'}}
        />
      </CardContent>
    </Card>
  );
};

ProfileDetails.propTypes = {
  borderRadius: PropTypes.number.isRequired,
  className: PropTypes.string,
  deleteFoto: PropTypes.func.isRequired,
  editor: PropTypes.object,
  file: PropTypes.object,
  height: PropTypes.number.isRequired,
  imgInfo: PropTypes.object,
  profile: PropTypes.object,
  setEditor: PropTypes.func.isRequired,
  setHeight: PropTypes.func.isRequired,
  setImgInfo: PropTypes.func.isRequired,
  setWidth: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired
};

export default ProfileDetails;

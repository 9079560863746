import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import theme from 'modules/theme.js';


const useStyles  = makeStyles(theme => ({
  root: {
    backgroundColor:  theme.palette.background.default
  },
}));

const Header = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      />
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;

import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const imgWithClick = { cursor: 'pointer' };

const Photo = ({ index, onClick, photo, margin,scrollPosition, direction, top, left }) => {
  const imgStyle = { margin: margin};
  if (direction === 'column') {
    imgStyle.position = 'absolute';
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = event => {
    onClick(event, { photo, index });
  };

  return (
    <LazyLoadImage
      style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
      {...photo}
      alt="img"
      effect="blur"
      onClick={onClick ? handleClick : null}
      scrollPosition={scrollPosition}
    />
  );
};

export default Photo;

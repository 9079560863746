
// --- Post bootstrap -----
import React, { useState, useEffect } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from 'modules/components/Typography';
import AppFooter from 'modules/views/AppFooter';
import AppAppBar from 'modules/views/AppAppBar';
import AppForm from 'modules/views/AppForm';
import { email, required } from 'modules/form/validation';
import { Button, TextField } from '@material-ui/core';
import FormButton from 'modules/form/FormButton';
import FormFeedback from 'modules/form/FormFeedback';
import Page  from 'components/Page';
import PropTypes from 'prop-types';
import { login } from 'actions';
import validate from 'validate.js';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { userActions } from 'authorization/_actions';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'Обязательный' },
    presence: true
  },
  password: {
    presence: { allowEmpty: false, message: 'Обязательный' }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  windowDiv: {
    backgroundColor: theme.palette.background.default,
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

const SignIn = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [mounted, setMounted] = useState(false);
  const dispatch = useDispatch();


  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleSubmit = () => {
    event.preventDefault();
    dispatch(login());
    if (formState.values.email && formState.values.password) {
      dispatch(userActions.login(formState.values.email, formState.values.password));
    }
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.windowDiv} >
      <Page
        className={classes.root}
        title="SignIn"
      >
        <AppAppBar page="home"/>
        <AppForm       style = {{marginTop:70}}>
          <Typography
            align="center"
            gutterBottom
            marked="center"
            variant="h3"
          >
            Вход
          </Typography>
          <form
            {...rest}
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
          >
            <TextField
              error={hasError('email')}
              fullWidth
              helperText={hasError('email') ? formState.errors.email[0] : null}
              label="Login"
              name="email"
              onChange={handleChange}
              style = {{marginBottom:'20px'}}
              value={formState.values.email || ''}
              variant="outlined"
            />
            <TextField
              error={hasError('password')}
              fullWidth
              helperText={
                hasError('password') ? formState.errors.password[0] : null
              }
              label="Password"
              name="password"
              onChange={handleChange}
              type="password"
              value={formState.values.password || ''}
              variant="outlined"
            />
            <FormButton
              className={classes.button}
              color="secondary"
              disabled={!formState.isValid}
              fullWidth
              size="large"
            >
              {'Войти'}
            </FormButton>
          </form>
        </AppForm>
      </Page>
    </div>
  );
}

SignIn.propTypes = {
  className: PropTypes.string
};

export default SignIn;

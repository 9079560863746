import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  TableSortLabel
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import getInitials from 'utils/getInitials';
import { ReviewStars, GenericMoreButton, TableEditBar } from 'components';
import {getItem,deleteSection} from 'admin/Service/Service';
import $ from 'jquery';
import {config} from 'config';

const classes = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class TablePaginationActions extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.handleFirstPageButtonClick = this.handleFirstPageButtonClick.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
    this.handleLastPageButtonClick = this.handleLastPageButtonClick.bind(this);

  }

  handleFirstPageButtonClick(event)  {
    this.props.onChangePage(event, 0);
  }

  handleBackButtonClick(event) {
    this.props.onChangePage(event, this.props.page - 1);
  }

  handleNextButtonClick(event) {
    this.props.onChangePage(event, this.props.page + 1);
  }

  handleLastPageButtonClick(event) {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  }

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          aria-label="First Page"
          disabled={page === 0}
          onClick={this.handleFirstPageButtonClick}
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          aria-label="Previous Page"
          disabled={page === 0}
          onClick={this.handleBackButtonClick}
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          aria-label="Next Page"
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          onClick={this.handleNextButtonClick}
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          aria-label="Last Page"
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          onClick={this.handleLastPageButtonClick}
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

const rows = [
  { id: 'id', numeric: true, disablePadding: true, label: 'Номер'},
  { id: 'name', numeric: false, disablePadding: true, label: 'Наименование раздела' },
  { id: 'description', numeric: false, disablePadding: true, label: 'Описание' },
  { id: 'main', numeric: false, disablePadding: true, label: 'Родительский раздел' }
];


class EnhancedTableHead extends React.Component {

  createSortHandler (property){
    return  (event) => {
      this.props.onRequestSort(event, property);
    };
  }

  render() {
    const { onSelectAllClick, order, orderBy, rowCount,padding } = this.props;

    return (
      <TableHead>
        <TableRow>

          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                sortDirection={orderBy === row.id ? order : false}
                style = {{width:row.width,padding:row.id==='id' ? '15px' : padding}}
              >

                <Tooltip
                  enterDelay={300}
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  title="Сортировать"
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    { row.label }
                  </TableSortLabel>
                </Tooltip>

              </TableCell>

            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

class Results extends React.Component {

  constructor(props, context) {
    super(props, context);
    const { className, customers,pagination,setMounted,mounted } = props;
    this.state = {
      page: 0,
      rowsPerPage:10,
      order: 'desc',
      orderBy: 'id',
      search:'',
    };
    this.setPage = this.setPage.bind(this);
    this.setRowsPerPage = this.setRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.onDeleteSection = this.onDeleteSection.bind(this);
    this.afterDelete = this.afterDelete.bind(this);

  }

  setPage(page){
    this.state.page = page;
    this.setState({ page: page });
  }

  setRowsPerPage(rowsPerPage){
    this.state.rowsPerPage = rowsPerPage;
    this.setState({ rowsPerPage: rowsPerPage });
  }

  handleChangePage(event, page) {
    console.log(this.props.pagination);
    this.setPage(page);
    var search = $('#search').val();
    console.log(page);
    this.props.getData(page,this.state.rowsPerPage,this.state.orderBy,this.state.order,this.props.setMounted,this.state.search);
  }

  afterDelete(){
    console.log(this.props.pagination);
    this.setPage(this.state.page);
    var search = $('#search').val();
    this.props.getData(this.state.page,this.state.rowsPerPage,this.state.orderBy,this.state.order,this.props.setMounted,this.state.search);
  }

  onDeleteSection(id){
    console.log('RRRR');
    deleteSection(id,this.afterDelete);
  }

  handleChangeRowsPerPage(event){
    this.setRowsPerPage(event.target.value);
    var search = $('#search').val();
    this.props.getData(0,this.state.rowsPerPage,this.state.orderBy,this.state.order,this.props.setMounted,this.state.search);
    console.log(event.target.value);
    console.log(this.state.page);
  }


  componentWillMount() {

    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions(){

  }


  handleRequestSort(event, property){
    const orderBy = property;
    let order = (this.state.order === 'desc' ? 'asc': 'desc');
    this.state.order = order;
    this.setState({ order: order });
    this.state.orderBy = property;
    this.setState({ orderBy: property });
    console.log(order);
    this.props.getData(this.state.page,this.state.rowsPerPage,orderBy,order,this.props.setMounted,this.state.search);
    var pagination = getItem('pagination');
    console.log('direction: ' +  pagination.sort.direction);
    console.log('property: ' + pagination.sort.property);
  }

  handleSelectAllClick(event) {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  }


  render() {
    const totalElements = (this.props.pagination && this.props.pagination.totalElements ? this.props.pagination.totalElements:0);
    const numberOfElements =  (this.props.pagination && this.props.pagination.numberOfElements ? this.props.pagination.numberOfElements:0);
    const number =  (this.props.pagination && this.props.pagination.number ? this.props.pagination.number:0);
    const totalPages =  (this.props.pagination && this.props.pagination.totalPages ? this.props.pagination.totalPages : 0);
    const search = this.props.search;
    const padding = '5px';
    const { order, orderBy, rowsPerPage, page } = this.state;
    return (
      <div
        className={clsx(classes.root, this.props.className)}
      >
        <Typography
          color="textSecondary"
          gutterBottom
          variant="body2"
        />
        <Card style = {{minWidth: '1000px'}}>
          <CardHeader
            title="Все разделы"
          />
          <Divider />
          <div  >
            <Table  style = {{width:'100%'}}>
              <EnhancedTableHead
                onRequestSort={this.handleRequestSort}
                onSelectAllClick={this.handleSelectAllClick}
                order={this.state.order}
                orderBy={this.state.orderBy}
                padding = {padding}
                rowCount={totalElements}
              />
              <TableBody>
                {this.props.mounted && this.props.customers && this.props.customers.length>0 && stableSort(this.props.customers, getSorting(this.state.order, this.state.orderBy))
                  .map((customer,i) => (
                    <TableRow
                      hover
                      key={customer.id}
                      selected = {i%2!==0 ? true:false}
                      style = {{width:'100%'}}
                    >
                      <TableCell
                        padding = "none"
                        style = {{width: rows[1].width,padding: padding}}
                      >
                        <div
                          className={classes.nameCell}
                          style = {{display: 'flex',alignItems: 'center',width:'70px'}}
                        >
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={'/section/' + customer.id + '/summary'}
                            variant="h6"
                          >
                            <Avatar
                              className={classes.avatar}
                              src={customer.brandImage!='undefined' && customer.brandImage!=null ? customer.brandImage: '/images/avatars/DefaultAvatar.jpg'}
                            >
                              {customer.automaker}
                            </Avatar>
                          </Link>
                          <div style = {{marginLeft:'20px'}}>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              style = {{whiteSpace:'nowrap',textDecoration: 'underline',color: 'green'}}
                              to={'/section/' + customer.id + '/summary'}
                              variant="h6"
                            >
                              {customer.id}
                            </Link>
                          </div>
                        </div>

                      </TableCell>
                      <TableCell
                        padding = "none"
                        style = {{width: rows[1].width,padding: padding}}
                      >{customer.name && customer.name}</TableCell>
                      <TableCell
                        padding = "none"
                        style = {{width: rows[1].width,padding: padding}}
                      >{customer.shortDescription && customer.shortDescription}</TableCell>
                      <TableCell
                        padding = "none"
                        style = {{width: rows[1].width,padding: padding}}
                      >{customer.mainProductId && customer.mainProductId}</TableCell>
                      <TableCell
                        padding = "none"
                        style = {{width: rows[1].width,padding: padding}}
                      >{customer.id!==1 && <Button
                        variant="outlined"
                        color="secondary"
                        onClick = {() =>this.onDeleteSection(customer.id)}
                                           >Удалить</Button>}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
          <CardActions
            className={classes.actions}
            style = {{justifyContent: 'flex-end',marginTop:'-10px',marginRight:'0px'}}
          >
            <TablePagination
              ActionsComponent={TablePaginationActionsWrapped}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              component="div"
              count={totalElements}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              page={number}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10,25,50,100]}
            />

          </CardActions>
        </Card>
      </div>
    );
  }
}

Results.propTypes = {
  className: PropTypes.string.isRequired,
  customers: PropTypes.array.isRequired,
  getData: PropTypes.func,
  mounted: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  search: PropTypes.string.search,
  setMounted: PropTypes.func
};

Results.defaultProps = {
  customers: [],
  pagination:{},
  className:'',
  search:''
};

export default Results;

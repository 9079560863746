import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import {Hidden} from '@material-ui/core';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import clsx from 'clsx';

function Copyright() {
  return (
    <React.Fragment>
      {'� '}
      <Link
        color="inherit"
        href="https://material-ui.com/"
      >
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const backgroundImage =
  '/images/site/rye2.png';

  const fhererImageNight =
        '/images/site/night2.png';


          const backgroundCandel =
            '/images/site/candel1.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    minHeight: 800,
    display: 'flex',
    backgroundImage: `url(${backgroundImage})`,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginTop: 0,
    marginLeft: '0.1%',
    marginRight: '0.1%'
  },
  backgroundCandel: {
    width: '200px',
    backgroundPosition: 'center',
    marginTop:-20,
    zIndex:300,
  },
  container: {
    marginTop: theme.spacing(45),
    marginBottom: theme.spacing(8),
    display: 'flex',

    minWidth: '95%'
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
    filter: theme.palette.imageFilter,
  },
  description: {
    color: theme.palette.primary.contrastText
  },
  icon: {
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '&:hover': {
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  link: {
    color: theme.palette.primary.contrastText
  },
}));

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  },
  {
    code: 'fr-FR',
    name: 'Francais',
  },
];

export default function AppFooter() {
  const classes = useStyles();

  return (
    <div>
    <Typography
      className={classes.root}
      component="footer"
    >
      <Container className={classes.container}>
      <Grid
        container
         direction="column"
      >
      <Grid
        item
      >
        <Grid
          container
           direction="row"
           style={{justifyContent:'space-evenly',alignItems:'center'}}

        >
        <Grid
          item
        >
          <Grid
            item
            md={4}
            sm={1}
            xs={4}
          >
            <Grid
              className={classes.icons}
              item
            >
            <Hidden mdDown>
            <Typography className={classes.icons} style = {{fontSize: 25, minWidth:500}}>
              Столярное направление Дионисий:
            </Typography>
            </Hidden>
            <Hidden lgUp>
            <Typography className={classes.icons} style = {{fontSize: 20, minWidth:300}}>
              Столярное направление Дионисий:
            </Typography>
            </Hidden>
            </Grid>
              <Grid
                className={classes.icons}
                item
              >
                <a
                  className={classes.icon}
                  href="https://www.facebook.com/people/Денис-Миронов/100045987403521"
                >
                  <img
                    alt="Facebook"
                    src="/images/site/sotial/facebook.png"
                  />
                </a>
                <a
                  className={classes.icon}
                  href="https://www.instagram.com/denis_mir_on_off/?r=nametag"
                >
                  <img
                    alt="Instagram"
                    src="/images/site/sotial/instagram.png"
                  />
                </a>
                <a
                  className={classes.icon}
                  href="https://vk.com/denis_mir_on_ov"
                >
                  <img
                    alt="ВК"
                    src="/images/site/sotial/vk.png"
                  />
                </a>
              </Grid>
              <Typography
                className={classes.description}
                gutterBottom
                marked="left"
                variant="h6"
              >
              Телефон
              </Typography>
              <Grid
                className={classes.icons}
                style={{minWidth:300}}
                item
              >
                <div style={{ marginTop: '4px',marginRight: '5px' }}>
                  <img
                    alt="Viber"
                    src="/images/site/sotial/viber.png"
                  />
                </div>
                <Hidden mdDown>
                <Typography style = {{fontSize: 30}}>
                <Link  target="_blank" className={classes.link} href="tel:+375257150229">+375 25 715 02 29</Link>
                </Typography>
                </Hidden>
                <Hidden lgUp>
                <Typography style = {{fontSize: 25}}>
                <Link  target="_blank" className={classes.link} href="tel:+375257150229">+375 25 715 02 29</Link>
                </Typography>
                </Hidden>
              </Grid>
              <Typography
                className={classes.description}
                gutterBottom
                marked="left"
                variant="h6"
              >
              e.mail
              </Typography>
              <Grid
                className={classes.icons}
                item
              >
              <Grid
                className={classes.icons}
                item
              >
                <MailIcon style={{ fontSize: 40 }}/>
                <Hidden mdDown>
                <Typography style = {{fontSize: 30}}>
                <Link  target="_blank" className={classes.link} href="mailto:drevodel999@gmail.com">drevodel999@gmail.com</Link>
                </Typography>
                </Hidden>
                <Hidden lgUp>
                <Typography style = {{fontSize: 20}}>
                <Link  target="_blank" className={classes.link} href="mailto:drevodel999@gmail.com">drevodel999@gmail.com</Link>
                </Typography>
                </Hidden>
              </Grid>
              </Grid>
               </Grid>
            </Grid>

            <Grid
              item
            >
          <Grid
            item
            style = {{marginTop:20}}
            md={4}
            sm={1}
            xs={4}
          >
          <img
            src={`${backgroundCandel}`}
            className={clsx(classes.backgroundCandel)}
            style={{marginTop:-30,filter:'sepia(0%) hue-rotate(0deg) saturate(300%) contrast(100%) brightness(140%)'}}
          >
          </img>
          </Grid>
          </Grid>

          <Grid
            item
          >
          <Grid
          item
          md={4}
          sm={1}
          xs={4}
          >
          <Grid
            className={classes.icons}
            item
          >
          <Hidden mdDown>
          <Typography className={classes.icons} style = {{fontSize: 25, minWidth:500}}>
            Художественное направление Иулия:
          </Typography>
          </Hidden>
          <Hidden lgUp>
          <Typography className={classes.icons} style = {{fontSize: 20, minWidth:300}}>
            Художественное направление Иулия:
          </Typography>
          </Hidden>
          </Grid>
            <Grid
              className={classes.icons}
              item
            >

              <a
                className={classes.icon}
                href="https://www.instagram.com/restavrator_juliy?igsh=eGRmdzBlcXZtNmdi"
              >
                <img
                  alt="Instagram"
                  src="/images/site/sotial/instagram.png"
                />
              </a>

            </Grid>
            <Typography
              className={classes.description}
              gutterBottom
              marked="left"
              variant="h6"
            >
            Телефон
            </Typography>
            <Grid
              className={classes.icons}
              style={{minWidth:300}}
              item
            >
              <div style={{ marginTop: '4px',marginRight: '5px' }}>
                <img
                  alt="Viber"
                  src="/images/site/sotial/viber.png"
                />
              </div>
              <Hidden mdDown>
              <Typography style = {{fontSize: 30}}>
              <Link  target="_blank" className={classes.link} href="tel:+375256858330">+375 25 685 83 30</Link>
              </Typography>
              </Hidden>
              <Hidden lgUp>
              <Typography style = {{fontSize: 25}}>
              <Link  target="_blank" className={classes.link} href="tel:+375256858330">+375 25 685 83 30</Link>
              </Typography>
              </Hidden>
            </Grid>


          </Grid>
</Grid>


          </Grid>


      </Grid>
      <Grid
        item
      >
      <Grid
        container
         direction="row"
         style={{justifyContent:'space-evenly',alignItems:'center',marginTop: 50}}

      >
      <Hidden mdDown>
        <Grid item >
          <Grid
            className={classes.icons}
            item
            style = {{minWidth:'900px'}}
          >
            <PersonPinCircleIcon style={{ fontSize: 40 }}/>
            <Typography style = {{fontSize: 30}}>
                 <Link  className={classes.link} target="_blank" href="https://yandex.by/maps/org/drevo_delo_by/174752227758/?utm_medium=mapframe&utm_source=maps">
                    Адрес: РБ, Минская область, г.Жодино, ул.Заречная, д.28.
                 </Link>
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Grid item >
          <Grid
            className={classes.icons}
            item
            style = {{marginLeft:'0px'}}
          >
            <PersonPinCircleIcon style={{ fontSize: 40 }}/>
            <Typography style = {{fontSize: 30,minWidth:'100px'}}>
            <Link  className={classes.link} target="_blank" href="https://yandex.by/maps/org/drevo_delo_by/174752227758/?utm_medium=mapframe&utm_source=maps">
          Адрес: РБ, Минская область, г.Жодино, ул.Заречная, д.28.
          </Link>
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
      </Grid>
</Grid>
</Grid>
</Container>
    </Typography>
    </div>
  );
}

import React, { useState, useCallback,useEffect  } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import AvatarEditor from 'react-avatar-editor';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import MaiIcon from '@material-ui/icons/MailOutline';

import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Gallery from 'react-photo-gallery';
import Photo from './components/Photo';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import  FilesDropzone  from 'admin/FilesDropzone';
import { addEditImage} from 'admin/Service/Service';
import { deleteImages} from 'admin/Service/Service';
import {SuccessSnackbar, ErrorSnackbar,InfoSnackbar,WarningSnackbar} from 'admin/Snackbar';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import {config} from 'config';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  sendButton: {
    marginTop: theme.spacing(2)
  },
  mailIcon: {
    marginRight: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(2)
  },
  cell: {
    padding: theme.spacing(1)
  }
}));


const Foto = props => {
  const { customer, className,updateInages,productId,setPhotos,photos,block,openLightbox,seletImageShow,imageShow, ...rest } = props;

  const SortablePhoto = SortableElement(item => <Photo {...item} />);
  const SortableGallery = SortableContainer(({ items,scrollPosition }) => (
    <Gallery
      photos={items}
      renderImage={props => <SortablePhoto   {...props}/>}
    />
  ));

  const classes = useStyles();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if(oldIndex!==newIndex){
      var ph = arrayMove(photos, oldIndex, newIndex);
      setPhotos(ph);
      imageShow(ph,newIndex);
    }else{
      seletImageShow(photos,newIndex)
    }
  };



  return (
    <div>
      {block &&   <Gallery
        onClick={openLightbox}
        photos={photos}
      />}
      {!block && <SortableGallery
        axis={'xy'}
        items={photos}
        onSortEnd={onSortEnd}
        openLightbox = {openLightbox}
      />}
    </div>
  );
};

Foto.propTypes = {
  block: PropTypes.boolean,
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
  imageShow: PropTypes.func.isRequired,
  openLightbox: PropTypes.func.isRequired,
  photos: PropTypes.array,
  productId: PropTypes.number,
  seletImageShow: PropTypes.func.isRequired
};

export default Foto;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Header = props => {
  const { className,id, ...rest } = props;

  const classes = useStyles();
  const urlClose = '/sections';

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid item >
        <Typography
          component="h2"
          gutterBottom
          variant="overline"
        >
        Раздел
        </Typography>
      </Grid>
      <Grid
        item
        style = {{marginLeft:'auto',marginBottom:10}}
      >
        <Button
          color="primary"
          component={RouterLink}
          href="#outlined-buttons"
          to={urlClose}
          variant="outlined"
        >
        Закрыть
        </Button>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number
};

export default Header;

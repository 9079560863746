import React, { useState, useEffect } from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {getProductsChildrenMain} from 'admin/Service/Service';
import { Redirect } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import LensIcon from '@material-ui/icons/Lens';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
const useStyles = makeStyles({
  root: {
    marginRight:'20px',
    background: '#4caf50',
    color:'white'
  },
});


export default function Menu() {
  const classes = useStyles();
  const [mounted, setMounted] = useState(false);
  const [images,setImages] = useState([]);

  const updateProductsChildren = (products) =>{
    var img = [];
    products.forEach((item, i) => {
      img.push({
        id: item.id,
        img: '/' + item.brandImage,
        name: item.name
      });
    });
    setImages(img);
    setMounted(true);
  }

  useEffect(() => {
    let m = true;
    const fetchProfile = () => {
      setMounted(false);
      getProductsChildrenMain(1,setMounted,updateProductsChildren);
    };
    fetchProfile();

    return () => {
      m = false;
    };

  }, []);
  return (
    <List>
      { mounted  && images.map((img, i) => {
        return (
          <ListItem
            button
            key={i}
          >

            <Link href={'/views/' + img.id} >
              <Typography variant="inherit">{img.name}</Typography>
            </Link>
          </ListItem>


        )})}

    </List>
  );
}

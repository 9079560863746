/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { PrivateRoute } from '../authorization/_components';
import {Home,SignIn,Sections,AddSections,EditSections,SectionDetails,Galary,ViewSections,ExelImport} from 'admin';
import ErrorLayout from 'template/Error';

const Error401 = lazy(() => import('template/views/Error401'));
const Error404 = lazy(() => import('template/views/Error404'));
const Error500 = lazy(() => import('template/views/Error500'));

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />
  },
  {
    path: '/home',
    component: Home,
    routes: [
      {
        path: '/home',
        exact: true,
        component: lazy(() => import('admin/Home'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/views/:id',
    component: ViewSections,
    routes: [
      {
        path: '/views/:id',
        exact: true,
        component: lazy(() => import('admin/ViewSections'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/sign-in',
    component: SignIn,
    routes: [
      {
        path: '/sign-in',
        exact: true,
        component: lazy(() => import('admin/SignIn'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: () => <Error401 />
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: () => <Error404 />
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: () => <Error500 />
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/sections',
    component: Sections,
    routes: [
      {
        path: '/sections',
        exact: true,
        component: <PrivateRoute
          component={Sections}
          exact
          path="/sections"
        />
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/section/:id/:tab',
    component: SectionDetails,
    routes: [
      {
        path: '/section/:id/:tab',
        exact: true,
        component: () => <PrivateRoute
          component={SectionDetails}
          exact
          path="/section/:id/:tab"
        />
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/images/:id/:tab',
    component: Galary,
    routes: [
      {
        path: '/images/:id/:tab',
        exact: true,
        component: () => <PrivateRoute
          component={Galary}
          exact
          path="/images/:id/:tab"
        />
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/emails',
    component: ExelImport,
    routes: [
      {
        path: '/emails',
        exact: true,
        component: () => <PrivateRoute
          component={ExelImport}
          exact
          path="/emails"
        />
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/addSection',
    component: AddSections,
    routes: [
      {
        path: '/addSection',
        exact: true,
        component: <PrivateRoute
          component={AddSections}
          exact
          path="/addSection"
        />
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/editSection/:id',
    component: EditSections,
    routes: [
      {
        path: '/editSection/:id',
        exact: true,
        component: <PrivateRoute
          component={EditSections}
          exact
          path="/editSection/:id"
        />
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;

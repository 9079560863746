import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import clsx from 'clsx';
import {config} from 'config';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { Grid } from '@material-ui/core';
import {Button} from '@material-ui/core';
import { Page } from 'components';
import {getStoreMain,getItem,getProductsChildren} from 'admin/Service/Service';
import { DialogShow } from './components';
import AppFooter from 'modules/views/AppFooter';
import AppAppBar from 'modules/views/AppAppBar';
import ProductSmokingHero from 'modules/views/ProductSmokingHero';
import Typography from 'template/modules/components/Typography';
import Container from '@material-ui/core/Container';
import ButtonBase from '@material-ui/core/ButtonBase';
import Menu from 'admin/Menu';
import {Hidden} from '@material-ui/core';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from 'react-helmet';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import {
  ViberShareButton,
  TelegramShareButton,
  FacebookShareButton,
  VKShareButton,
  OKShareButton
} from 'react-share';

const drawerWidth = 240;

const fhererImageNight =
      '/images/site/night2.png';

const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  root: {
    display: 'flex',
    background: theme.palette.background.image,
    backgroundColor: theme.palette.secondary.light,
    width:'100%'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuButtonUp: {
    marginLeft: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerPaperUp: {
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 10,
  },
  contentUp: {
    marginLeft: 10,
    marginTop: 50
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: -drawerWidth,
  },
  container: {
    marginBottom: theme.spacing(10),
    marginLeft:'50px;',
    display: 'flex',
    position: 'relative',
  },
  item: {
    marginBottom:30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
    margin: '0px'
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
  images: {
    marginTop: theme.spacing(8),
    display: 'flex',
    marginLeft:'50px',
    marginRight:'50px'
  },
  imageWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: 100,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  ToolbarClass:{
    marginTop:70
  },
  ToolbarClassUp:{
    marginTop:120
  },
  tooltipClass:{
    position:'fixed',
    marginTop: -100,
    zIndex:100
  },
  tooltipClassUp:{
    position:'fixed',
    marginTop: 0,
    marginLeft:10,
    zIndex:100
  },
  footer:{
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 10,
  },
  footerUp:{
    backgroundColor: theme.palette.background.default,
    marginLeft: 10,
    marginTop: 50
  }

}));




const ViewSections = props => {
  const { match, history } = props;
  const classes = useStyles();
  const { id } = match.params;
  const [mounted, setMounted] = useState(false);
  const [count, setCount] = useState(1);
  const [descriptionImage, setDescriptionImage] = useState(null);
  const [main, setMain] = useState(null);
  const [photos,setPhotos] = useState([]);
  const [images,setImages] = useState([]);
  const [imgList,setImgList] = useState([]);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openUp, setOpenUp] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerUpOpen = () => {
    setOpenUp(true);
  };

  const handleDrawerUpClose = () => {
    setOpenUp(false);
  };


  useEffect(() => {
    let m = true;
    console.log('ViewSections');
    const fetchProfile = () => {
      updateInages();
    };
    fetchProfile();

    return () => {
      m = false;
    };

  }, []);


  const updateInages = ()=>{
    setMounted(false);
    getStoreMain(id,setMounted,updatePhotos);
  }

  const sortForSortProduct = (productResult,images) =>{
    var sortedIds = productResult.sort!=undefined && productResult.sort!='undefined' && productResult.sort!=null && productResult.sort!='' ? (productResult.sort + '').split(','):[];
    var newImages = [];
    if(images!=null && images!='undefined' && images!=undefined && sortedIds.length==images.length){
      sortedIds.forEach((itemId, i) => {
        newImages.push(images.find(item => item.id === Number(itemId)));
      })
    }
    return newImages.length>0 ? newImages : ((images!=null && images!='undefined' && images!=undefined) ? images : []);
  }

  const updatePhotos = (imag) =>{
    var ph = [];
    var product = getItem('data');
    imag = sortForSortProduct(product,imag);
    imag.forEach((item, i) => {
      ph.push({
        key:item.id,
        id: item.id,
        src: '/' + item.img,
        width: item.width!=null ? item.width: 1,
        height: item.height!=null ? item.height: 1,
        selected:item.main,
        shortDescription: item.description,
        main: item.main
      });
    });
    var imgs = [];
    imag.forEach((item, i) => {
      imgs.push({
        original: '/' + item.img,
        thumbnail: '/' + item.img
      });
    });
    setImgList(imgs);
    setPhotos(ph);
    setMounted(false);
    getProductsChildren(getItem('data').id,setMounted,updateProductsChildren);
  }

  const updateProductsChildren = (products) =>{
    var img = [];
    products.forEach((item, i) => {
      img.push({
        id: item.id,
        img: '/' + item.brandImage,
        title: item.name,
        width: '40%'
      });
    });
    setImages(img);
  }

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const viberUrl = `https://drevo-delo.by/views/${id}?socialId=${id}`
  const viberTitle = `${getItem('data') && getItem('data').title ? getItem('data').title : 'Раздел древодельных работ'}`;
  const viberDescription = `${getItem('data') && getItem('data').shortDescription ? getItem('data').shortDescription : ''}`;
  const telegramUrl = `https://drevo-delo.by/views/${id}?socialId=${id}`;
  const telegramTitle = `${getItem('data') && getItem('data').title ? getItem('data').title : 'Раздел древодельных работ'}`;
  const facebookrUrl = `https://drevo-delo.by/views/${id}?socialId=${id}`
  const facebookTitle = `${getItem('data') && getItem('data').title ? getItem('data').title : 'Раздел древодельных работ'}`;
  const vkUrl = `https://drevo-delo.by/views/${id}?socialId=${id}`;
  const vkTitle = `${getItem('data') && getItem('data').title ? getItem('data').title : 'Раздел древодельных работ'}`;
  const vkImage = `${getItem('data') && getItem('data').brandImage ? getItem('data').brandImage : 'https://drevo-delo.by/images/site/header.jpg'}`;
  const okUrl = `https://drevo-delo.by/views/${id}?socialId=${id}`;
  const okTitle = `${getItem('data') && getItem('data').title ? getItem('data').title : 'Раздел древодельных работ'}`;
  const okImage = `${getItem('data') && getItem('data').brandImage ? getItem('data').brandImage : 'https://drevo-delo.by/images/site/header.jpg'}`;
  const okDescription = `${getItem('data') && getItem('data').shortDescription ? getItem('data').shortDescription : ''}`;

  return (
    <div>
      <Page
        className={classes.root}
        title={getItem('data') && getItem('data').title ? getItem('data').title : 'Раздел древодельных работ'}
      >
        <Helmet>
          <title>{ getItem('data') && getItem('data').title ? getItem('data').title : 'Раздел древодельных работ'}</title>
          <meta
            content={getItem('data') && getItem('data').meta ? getItem('data').meta : 'Работы по дереву'}
            name="Description"
          />
        </Helmet>


        <div className={classes.root}>
          <CssBaseline />

          <AppAppBar
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            page = "home"
            position="fixed"
          />




          <Hidden lgUp>
            <Drawer
              className={clsx(classes.drawer, openUp && classes.hide)}
              classes={{
                paper: classes.drawerPaperUp,
              }}
              variant="permanent"
            >
              <Toolbar   className={classes.ToolbarClassUp}>
                <Typography
                  noWrap
                  variant="h6"
                >
          Продукция
                </Typography>
                <IconButton
                  aria-label="open drawer"
                  className={clsx(classes.menuButtonUp, open && classes.hide)}
                  color="inherit"
                  edge="start"
                  onClick={handleDrawerUpOpen}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              <Divider />
              <div className={classes.drawerContainer}>
                <Menu  />
              </div>
            </Drawer>


            <main className={clsx(classes.contentUp,  !openUp && classes.contentShift)}>
              <Toolbar />
              <ViberShareButton
                hashtag="#drevo-delo"
                separator={viberDescription}
                style={{marginLeft:5,marginRight:5,marginTop:30}}
                title={viberTitle}
                url={viberUrl}
              >
                <img
                  alt="Viber"
                  src={'/images/viber.svg'}
                />
              </ViberShareButton>
              <TelegramShareButton
                hashtag="#drevo-delo"
                style={{marginLeft:5,marginRight:5,marginTop:5}}
                title={telegramTitle}
                url={telegramUrl}
              >
                <img
                  alt="Telegramm"
                  src={'/images/telegram.svg'}
                />
              </TelegramShareButton>
              <FacebookShareButton
                hashtag="#drevo-delo"
                quote={facebookTitle}
                style={{marginLeft:5,marginRight:5,marginTop:5}}
                title={facebookTitle}
                url={facebookrUrl}
              >
                <img
                  alt="Facebook"
                  src={'/images/facebook.svg'}
                />
              </FacebookShareButton>
              <VKShareButton
                hashtag="#drevo-delo"
                image={vkImage}
                style={{marginLeft:5,marginRight:5,marginTop:5}}
                title={vkTitle}
                url={vkUrl}
              >
                <img
                  alt="VK"
                  src={'/images/vk.svg'}
                />
              </VKShareButton>
              <OKShareButton
                description={okDescription}
                hashtag="#drevo-delo"
                image={okImage}
                style={{marginLeft:5,marginRight:5,marginTop:5}}
                title={okTitle}
                url={okUrl}
              >
                <img
                  alt="OK"
                  src={'/images/ok.svg'}
                />
              </OKShareButton>
              <Typography
                align="center"
                component="h2"
                marked="center"
                style = {{padding:'50px'}}
                variant="h4"
              >
                {mounted && getItem('data').name}
              </Typography>
              <Tooltip
                className={clsx(classes.tooltipClassUp, !openUp && classes.hide)}
                title="Продукция"
              >
                <Fab
                  color="primary"
                  onClick={handleDrawerUpClose}
                  size="medium"
                  sx={{
                    bottom: 50,
                    marginRight: (theme) => theme.spacing(4),
                    marginLeft: (theme) => theme.spacing(4),
                    marginTop: (theme) => theme.spacing(4),
                    position: 'fixed',
                    right: 0,
                    zIndex: 1000
                  }}
                  title="Продукция"
                >
                  <ChevronRightIcon fontSize="small" />
                </Fab>
              </Tooltip>
              <div
                className={classes.containerUp}
                style = {{marginTop:'100px'}}
              >
                <Hidden lgUp>
                  <div className={classes.item}>
                    <Typography>
                      {mounted && getItem('data').shortDescription && ReactHtmlParser(getItem('data').shortDescription)}
                    </Typography>
                  </div>
                </Hidden >
              </div>
              <Hidden lgUp>
                {photos && id &&
                <Grid
                  container
                  spacing={5}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    { mounted && imgList && <DialogShow
                      block
                      customer={getItem('data')}
                      imgList = {imgList}
                      photos = {photos}
                      productId = {id}
                      setImgList = {setImgList}
                      setPhotos = {setPhotos}
                    /> }
                  </Grid>
                </Grid>
                }

              </Hidden >
              <Grid >
                <Grid
                  item
                  xs={12}
                >
                  <Hidden lgUp>
                    <div
                      className={classes.images}
                      style = {{    flexWrap: 'wrap'}}
                    >
                      {images.map(image => (
                        <ButtonBase
                          className={classes.imageWrapper}
                          key={image.title}
                          style={{
                            width: image.width,
                          }}
                        >
                          <div
                            className={classes.imageSrc}
                            style={{
                              backgroundImage: `url(${image.img})`,
                            }}
                          />
                          <div className={classes.imageBackdrop} />
                          <Button
                            className={classes.imageButton}
                            component="a"
                            href={'/views/' + image.id}
                          >
                            <Typography
                              className={classes.imageTitle}
                              color="inherit"
                              component="h3"
                              variant="h6"
                            >
                              {image.title}
                              <div className={classes.imageMarked} />
                            </Typography>
                          </Button>
                        </ButtonBase>
                      ))}
                    </div>
                  </Hidden>
                </Grid>
              </Grid>
            </main>
          </Hidden>

          <Hidden mdDown>
            <Drawer
              className={clsx(classes.drawer, open && classes.hide)}
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
            >
              <Toolbar   className={classes.ToolbarClass}>
                <IconButton
                  aria-label="open drawer"
                  className={clsx(classes.menuButton, open && classes.hide)}
                  color="inherit"
                  edge="start"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  noWrap
                  variant="h6"
                >
         Продукция
                </Typography>
              </Toolbar>
              <Divider />
              <div className={classes.drawerContainer}>
                <Menu  />
              </div>
            </Drawer>


            <main className={classes.content}>
              <Toolbar />
              <ViberShareButton
                hashtag="#drevo-delo"
                style={{marginLeft:5,marginRight:5,marginTop:5}}
                url={viberUrl}
              >
                <img
                  alt="Viber"
                  src={'/images/viber.svg'}
                />
              </ViberShareButton>
              <TelegramShareButton
                hashtag="#drevo-delo"
                style={{marginLeft:5,marginRight:5,marginTop:5}}
                title={telegramTitle}
                url={telegramUrl}
              >
                <img
                  alt="Telegramm"
                  src={'/images/telegram.svg'}
                />
              </TelegramShareButton>
              <FacebookShareButton
                hashtag="#drevo-delo"
                quote={facebookTitle}
                style={{marginLeft:5,marginRight:5,marginTop:5}}
                title={facebookTitle}
                url={facebookrUrl}
              >
                <img
                  alt="Facebook"
                  src={'/images/facebook.svg'}
                />
              </FacebookShareButton>
              <VKShareButton
                hashtag="#drevo-delo"
                image={vkImage}
                style={{marginLeft:5,marginRight:5,marginTop:5}}
                title={vkTitle}
                url={vkUrl}
              >
                <img
                  alt="VK"
                  src={'/images/vk.svg'}
                />
              </VKShareButton>
              <OKShareButton
                description={okDescription}
                hashtag="#drevo-delo"
                image={okImage}
                style={{marginLeft:5,marginRight:5,marginTop:5}}
                title={okTitle}
                url={okUrl}
              >
                <img
                  alt="OK"
                  src={'/images/ok.svg'}
                />
              </OKShareButton>

              <Typography
                align="center"
                component="h2"
                marked="center"
                style = {{padding:'50px'}}
                variant="h4"
              >
                {mounted && getItem('data').name}
              </Typography>
              <Hidden mdDown>
                <Tooltip
                  className={clsx(classes.tooltipClass, !open && classes.hide)}
                  title="Продукция"
                >
                  <Fab
                    color="primary"
                    onClick={handleDrawerClose}
                    size="medium"
                    sx={{
                      bottom: 20,
                      marginRight: (theme) => theme.spacing(4),
                      marginLeft: (theme) => theme.spacing(4),
                      marginTop: (theme) => theme.spacing(4),
                      position: 'fixed',
                      right: 0,
                      zIndex: 1000
                    }}
                    title="Продукция"
                  >
                    <ChevronRightIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              </Hidden>
              <div
                className={classes.container}
                style = {{marginTop:'100px'}}
              >

                {photos && id &&
            <Hidden mdDown>
              { photos.length>0 &&  id &&
              <div>
                <Grid
                  container
                  spacing={5}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <div className={classes.item}>
                      <Typography>
                        {mounted && getItem('data').shortDescription && ReactHtmlParser(getItem('data').shortDescription)}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <div>
                  <Grid
                    container
                    spacing={5}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      { mounted && imgList && <DialogShow
                        block={false}
                        customer={getItem('data')}
                        imgList = {imgList}
                        photos = {photos}
                        productId = {id}
                        setImgList = {setImgList}
                        setPhotos = {setPhotos}
                      /> }
                    </Grid>
                  </Grid>
                </div>
              </div>
              }
            </Hidden>
                }


              </div>

              <Grid >
                <Grid
                  item
                  xs={12}
                >
                  <Hidden mdDown>
                    <div className={classes.images} >
                      {images.map(image => (
                        <ButtonBase
                          className={classes.imageWrapper}
                          key={image.title}
                          style={{
                            width: image.width,
                          }}
                        >
                          <div
                            className={classes.imageSrc}
                            style={{
                              backgroundImage: `url(${image.img})`,
                            }}
                          />
                          <div className={classes.imageBackdrop} />
                          <Button
                            className={classes.imageButton}
                            component="a"
                            href={'/views/' + image.id}
                          >
                            <Typography
                              className={classes.imageTitle}
                              color="inherit"
                              component="h3"
                              variant="h6"
                            >
                              {image.title}
                              <div className={classes.imageMarked} />
                            </Typography>
                          </Button>
                        </ButtonBase>
                      ))}
                    </div>
                  </Hidden>
                </Grid>
              </Grid>
            </main>
          </Hidden>

        </div>
      </Page>
      <Hidden lgUp>
        <AppFooter className={classes.footerUp}/>
      </Hidden>

      <Hidden mdDown>
        <AppFooter className={classes.footer}/>
      </Hidden>
    </div>
  );
};


ViewSections.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default connect (
  (state, props) => ({
    authentication: state.authentication
  }),
  dispatch => ({
    pushMessage: (queue) => {
      dispatch({ type: 'SAVE_QUEUE', payload: queue })
    }
  })
)(ViewSections);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

import { Button } from '@material-ui/core';
const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 5),
  },
  image: {
    height: 60,
    filter: theme.palette.imageFilter
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textTransform: 'uppercase',
    textAlign:  'center',
    fontWeight: 700,
    fontFamily: 'SynodDisplay',
    color: theme.palette.primary.contrastText
  },
  description: {
    color: theme.palette.primary.contrastText
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',

  },
  button: {
    minWidth: 200,
    marginTop:20
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container>
          <Grid
            item
            md={4}
            xs={12}
          >
            <div className={classes.item}>
              <img
                alt="резьба по дереву"
                className={classes.image}
                src="/images/site/wonder.png"
              />
              <Typography
                className={classes.title}
                variant="h6"
              >
                Резьба по дереву
              </Typography>
              <Typography
                className={classes.description}
                variant="h6"
              >
                {'Производиться как ремесленным способом так и при помощи станков с ЧПУ. '}
                {'В зависимости от поставленной задачи возможно изготовление любых элементов. 3-4-5d.'}
              </Typography>
              <Button
                className={classes.button}
                color="secondary"
                component="a"
                href="/views/6"
                size="large"
                variant="contained"
              >
                Посмотреть
              </Button>
            </div>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <div className={classes.item}>
              <img
                alt="реставрационные работы"
                className={classes.image}
                src="/images/site/church.png"
                style = {{height: '100px',marginTop:'-20px'}}
              />
              <Typography
                className={classes.title}
                style = {{marginTop:'20px'}}
                variant="h6"
              >
                Реставрационные работы
              </Typography>
              <Typography
                className={classes.description}
                variant="h6"
              >
                {'Возможно восстановить, либо реконструировать любой предмет из дерева. '}
                {'Восстановление Икон, досок для икон, киотов и других церковных пренадлежностей.'}
              </Typography>
              <Button
                className={classes.button}
                color="secondary"
                component="a"
                href="/views/7"
                size="large"
                variant="contained"
              >
                Посмотреть
              </Button>
            </div>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <div className={classes.item}>
              <img
                alt="уникальные вещи"
                className={classes.image}
                src="/images/site/carpet.png"
              />
              <Typography
                className={classes.title}
                variant="h6"
              >
                Отделочные работы
              </Typography>
              <Typography
                className={classes.description}
                variant="h6"
              >
                {'Золочение, серебрение и нанесение других материалов. '}
                {'Использование различных декоративных материалов: камень, металл, ткань и др. '}
                {'Красители и спецэффекты.'}
              </Typography>
              <Button
                className={classes.button}
                color="secondary"
                component="a"
                href="/views/8"
                size="large"
                variant="contained"
              >
                Посмотреть
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);

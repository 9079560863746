import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import {  TextField } from '@material-ui/core';
import Snackbar from '../components/Snackbar';
import { Button } from '@material-ui/core';
import {getItem,mailing} from 'admin/Service/Service';
import * as EmailValidator from 'email-validator';
import clsx from 'clsx';

const styles = theme => ({
  root: {
    minHeight:1000,
    marginBottom: 0,
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(35),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  cardWrapper: {
    backgroundColor: theme.palette.secondary.light,
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.light,
  },
  cardContent: {
    maxWidth: 400,
    backgroundColor: theme.palette.secondary.light,
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    width: '100%',
  },
  imagesWrapper: {
    position: 'relative',
    backgroundColor: theme.palette.secondary.light,
  },
  imageDots: {
    position: 'absolute',
    backgroundColor: theme.palette.secondary.light,
    width: '100%',
    background: 'url(/static/onepirate/productCTAImageDots.png)',
  },
  image: {
    position: 'absolute',
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 600,
  },
  messageText:{
    textTransform: 'uppercase',
    fontFamily: 'SynodDisplay',
    color: theme.palette.primary.contrastText
  }
});

function ProductCTA(props) {
  const { className,classes } = props;
  const [open, setOpen] = React.useState(false);
  const [mess, setMess] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [fio, setFIO] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleSubmit = event => {
    event.preventDefault();
    let eml = email!==undefined &&
                email!=='undefined' &&
                email!==null ? email.replace(/\s+/g,'') : '';
    console.log(email);
    if(eml!=='' && EmailValidator.validate(eml)){
      let data = {
        email: email,
        message:message,
        fio: fio
      };
      mailing(data,afterSubmit);
    }
    else if(eml!=='' && eml!=='undefined' && eml!==undefined && !EmailValidator.validate(eml)){
      setMess('Пожалуйста введите корректный email для связи с Вами.');
      setOpen(true);
    } else{
      let data = {
        email: '',
        message:message,
        fio: fio
      };
      mailing(data,afterSubmit);
    }
  };

  const changeEmail = event => {
    event.preventDefault();
    setEmail(event.target.value);
  }

  const changeFIO = event => {
    event.preventDefault();
    setFIO(event.target.value);
  }

  const changeMessage = event => {
    event.preventDefault();
    setMessage(event.target.value);
  }

  const afterSubmit = (message) => {
    if(message!==null && message!=='undefined' && message!==undefined){
      setMess(message.message);
      setOpen(true);
    }else{
      setMess('Пожалуйста введите корректный email для связи с Вами.');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section className={classes.root}>
      <Container
        className={classes.container}
        component="container"
      >
        <Grid container>
          <Grid
            item
            md={6}
            xs={12}
          >
            <div className={classes.card}>
              <form
                className={classes.cardContent}
                onSubmit={handleSubmit}
              >
                <Typography
                  className={classes.messageText}
                  component="h2"
                  gutterBottom
                  variant="h2"
                >
                 Оставьте сообщение
                </Typography>
                <Typography
                  className={classes.messageText}
                  variant="h5"
                >
                Пожалуйста!
                </Typography>
                <TextField
                  className={classes.textField}
                  noBorder
                  onChange = {changeEmail}
                  placeholder="Ваш email"
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  noBorder
                  onChange = {changeFIO}
                  placeholder="ФИО или организация"
                  variant="outlined"
                />
                <TextField
                  className={clsx(classes.textField, className)}
                  defaultValue={message}
                  id="message"
                  margin="normal"
                  multiline
                  onChange={changeMessage}
                  placeholder="Cообщение"
                  rows="4"
                  style={{width: '100%',float: 'left'}}
                  value = {message}
                  variant="outlined"
                />
                <Button
                  className={classes.button}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                Отправить нам
                </Button>
              </form>
            </div>
          </Grid>
          <Grid
            className={classes.imagesWrapper}
            item
            md={6}
            xs={12}
          >
            <Hidden smDown>
              <div className={classes.imageDots} />
              <img
                alt="call to action"
                className={classes.image}
                src="/images/site/keepme.jpg"
              />
            </Hidden>
          </Grid>
        </Grid>
        <Snackbar
          message={mess}
          onClose={handleClose}
          open={open}
        />
      </Container>
    </section>
  );
}

ProductCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCTA);

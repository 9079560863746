import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
  handleResponse,
  getDataSection,
  setItem,
  getItem,
  initRepository,
  setRedirect } from 'admin/Service/Service';
import axios from 'utils/axios';
import Service from 'admin/Service/Service';
import { Page } from 'components';
import { Header, Results, SearchBar } from './components';
import AppAppBar from 'modules/views/AppAppBar';

const useStyles  = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor:  theme.palette.background.default
  },
  divWindow: {
    backgroundColor:  theme.palette.background.default
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));


const Sections = props => {

  const { className,pushMessage,queue, ...rest } = props;
  const classes = useStyles();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);


  useEffect(() => {
    let m = true;
    initRepository();
    setMounted(false);
    const fetchProfile = () => {
      getDataSection(page,rowsPerPage,orderBy,order,setMounted);
    };
    fetchProfile();
    return () => {
      m = false;
    };

  }, []);


  const onSerch = (src) => {
    var pagination = getItem('pagination');
    console.log('EEEEE');
    if(src){
      getDataSection(pagination.number,pagination.numberOfElements,'id','asc',setMounted,src);
    }else{
      getDataSection(null,pagination.size,'id','asc',setMounted,src);
    }
    setSearch(src);
  };


  const handleFilter = () => {
  };


  return (
    <div
      className={classes.divWindow}
      style = {{marginTop:'20px',marginLeft:'0px',marginBottom:'10px'}}
    >
      <Page
        className={classes.root}
        style = {{width:'98%'}}
        title="Разделы"
      >
        <AppAppBar page="admin"/>
        <div style = {{marginTop:'100px'}}>
          <Header/>
          <SearchBar
            onFilter={handleFilter}
            onSearch={onSerch}
          />
          <Results
            className={classes.results}
            customers={getItem('data')}
            getData = {getDataSection}
            mounted = {mounted}
            pagination = {getItem('pagination')}
            search = {search}
            setMounted = {setMounted}
          />
        </div>
      </Page>
    </div>
  );
};

Sections.propTypes = {
  className: PropTypes.string
};

export default Sections;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {config} from 'config';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      sessionStorage.getItem('user')
        ? <Component {...props}/>
        : <Redirect to="/sign-in" />
    )}
  />
)

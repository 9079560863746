import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import {Service} from 'admin/Service/Service';
import { Page } from 'components';
import {
  Header,
  General
} from './components';

import {
  handleResponse,
  geClassificatorSection,
  setItem,
  getItem,
  initRepository,
  setRedirect } from 'admin/Service/Service';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
    color: theme.palette.primary.contrastText,
    backgroundColor:  theme.palette.background.default
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const AddSections = props => {
  const { match, history,queue,pushMessage } = props;
  const classes = useStyles();
  const { tab,id } = match.params;

  const [mounted, setMounted] = useState(false);

  const handleTabsChange = (event, value) => {
    history.push(value);
  };



  useEffect(() => {
    let m = true;
    console.log('AddSections');
    setMounted(false);
    const fetchProfile = () => {
      geClassificatorSection(setMounted);
    };
    fetchProfile();
    return () => {
      m = false;
    };

  }, []);

  return (
    <Page
      className={classes.root}
      title="Добавить Раздел"
    >
      {id!==null && <Header id = {Number(id)} />}
      <Divider className={classes.divider} />
      <div className={classes.content}>
        { mounted &&
         <General
           classificator = {getItem('data2')}
           id = {Number(id)}
         />
        }
      </div>
      <Service />
    </Page>
  );
};


AddSections.propTypes = {
  className: PropTypes.string
};

export default AddSections;

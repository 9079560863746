import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {config} from 'config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import {Service } from 'admin/Service/Service';
import {
  getSection,
  addEditSection,
  getItem
} from 'admin/Service/Service';
import { GeneralSettings } from './components';

const useStyles = makeStyles(() => ({
  root: {}
}));


const General = props => {
  const { className,id,classificator,customer, ...rest } = props;

  const classes = useStyles();
  const [mounted, setMounted] = useState(null);

  useEffect(() => {
    let m = true;
    const fetchProfile = () => {
      //getSection(id,setMounted);
      setMounted(true);
    };


    setMounted(false);
    fetchProfile();

    return () => {
      m = false;
    };

  }, []);

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >

      <Grid
        item
        lg={8}
        md={6}
        xl={9}
        xs={12}
      >

        { mounted && [1].map(it => (
          <GeneralSettings
            classificator = {classificator}
            edit = {addEditSection}
            id = {id}
            key = "1"
            profile={customer}
          />
        ))
        }
      </Grid>
    </Grid>
  );

};

General.propTypes = {
  className: PropTypes.string,
  classificator: PropTypes.array,
  customer: PropTypes.object,
  id: PropTypes.number
};

export default General;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme =>   ({
  root: {  color: theme.palette.primary.contrastText,}
}));

const Header = props => {
  const { className,customer, ...rest } = props;

  const classes = useStyles();


  const getName = (customer) => {
    return 'Раздел ' + customer.id + ' ' + customer.name;
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item >
          <Typography
            component="h1"
            variant="h3"
          >
            {getName(customer)}
          </Typography>
        </Grid>
        <Grid
          item
          style = {{marginLeft:'auto'}}
        >
          <Button
            color="primary"
            component={RouterLink}
            href="#outlined-buttons"
            to="/sections"
            variant="outlined"
          >
        Закрыть
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object
};

export default Header;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {userActions} from 'authorization/_actions';
import useRouter from 'utils/useRouter';
import moment from 'moment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { Link as RouterLink } from 'react-router-dom';
import Service from 'admin/Service/Service';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import PersonIcon from '@material-ui/icons/PersonOutline';

import { Label } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const CustomerInfo = props => {
  const { customer, className, ...rest } = props;

  const { history } = useRouter();

  const classes = useStyles();

  const [openEdit, setOpenEdit] = useState(false);

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleLogout = () => {
    userActions.logout();
    history.push('/login');
    // dispatch(logout());
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Раздел" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow
              padding = "none"
              selected
            >
              <TableCell>Наименование</TableCell>
              <TableCell>{customer.name}</TableCell>
            </TableRow>
            <TableRow
              padding = "none"
              selected
            >
              <TableCell>id родительского раздела</TableCell>
              <TableCell>{customer.mainProductId}</TableCell>
            </TableRow>
            <TableRow padding = "none">
              <TableCell>Описание</TableCell>
              <TableCell>{customer.shortDescription}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

CustomerInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

export default CustomerInfo;

import React, { lazy, useState,useEffect} from 'react';
import {Router,Route,Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../authorization/_helpers';
import { alertActions } from '../authorization/_actions';

import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';

import routes from '../template/routes';
import {restoreSettings,storeSettings} from '../template/SettingsContext';

import { store } from '../authorization/_helpers';

import '../template/mixins/chartjs';
import '../template/mixins/moment';
import '../template/mixins/validate';
import '../template/mixins/prismjs';
import '../template/assets/scss/index.scss';
import $ from 'jquery';
import { createCustomTheme } from '../template/modules/theme';

const App = props => {
  const { dispatch } = props;
  const { alert } = props;

  useEffect(() => {
    let m = true;
    sessionStorage.setItem('loginFailure',null);

    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });

  }, []);

  const settings =  restoreSettings()
  const [theme, setTheme] = useState(createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  }));

  return (
    <div style = {{minWidth:'100%',height:'100%'}}>
      {alert.message &&
                    <div className={`alert ${alert.type}`}>{alert.message}</div>
      }
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            {renderRoutes(routes)}
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );

};

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
